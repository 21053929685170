/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion, { AccordionItem } from '@ingka/accordion';
import Carousel from '@ingka/carousel';
import Text from '@ingka/text';
import './ProjectItemPanel.css';
import Modal, { Sheets, ModalHeader, ModalBody } from '@ingka/modal';
import React, { useEffect, useState } from 'react';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import SSRIcon from '@ingka/ssr-icon';
import { Divider } from 'antd';
import { useTranslate } from '../../../../common/services/translationService.js';
import { Item } from '../../createProject/items/Items';
import { getFullUrl, isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';
import useCallApi from '../../../../common/services/apiService.js';

interface ProjectItemPanelProps {
    isVisible: boolean;
    connectedItem: Item | any | null,
    onClose: () => void;
}

const carouselProps = {
  id: 'ItemDetailsCarousel',
  hasNoControls: false,
  prefix: '',
  ariaLabelLeftBtn: 'See previous items',
  ariaLabelRightBtn: 'See next items',
  isSlideShow: true,
};

const ProjectItemPanel = ({
  isVisible, connectedItem, onClose,
}: ProjectItemPanelProps) => {
  const [imageToken, setImageToken] = useState<any>();
  const { get } = useCallApi();

  const textDimensionMetric = useTranslate('itemDetails.dimensionMetric');
  const textMaterial = useTranslate('itemDetails.material');
  const textDimensionImperial = useTranslate('itemDetails.dimensionImperial');
  const pipItemNo = useTranslate('tab.items.item');
  const comments = useTranslate('customUnit.details.commentsLabel');
  const pipHfb = useTranslate('customUnit.overview.hfb');
  const pipPa = useTranslate('customUnit.overview.pa');
  const pipPrice = useTranslate('customUnit.overview.price');
  const pipReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const pipInfoText = useTranslate('customUnit.infoText');

  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      <Text className="item-date">
        {title}
        {title.includes(':') ? '' : ':'}
      </Text>
      <Text className="itemSubTitle">{data}</Text>
    </div>
  );

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  useEffect(() => {
    getSasToken();
  }, []);

  const renderTabItems = (title: string, data: string, end?: boolean) => (
    <>
      <div className="pipSection">
        <Text className="pipLabel">{title}</Text>
        <Text className="pipValue">{data}</Text>
      </div>
      {!end ? <Divider type="vertical" /> : null}
    </>
  );

  return (
    <Modal
      visible={isVisible}
      focusLockProps={{
        disabled: false,
      }}
      handleCloseBtn={onClose}
    >
      <Sheets
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <ModalHeader ariaCloseTxt="Close button text" title={useTranslate('inst.comp.itemDetailsTitle')} />
      }
      >
        <ModalBody className="pipModelBody">
          {!isNullEmptyOrUndefined(connectedItem?.comparisonItemImageViewEntity) && (
          <Carousel {...carouselProps}>
            {connectedItem?.comparisonItemImageViewEntity?.map(
              (item:any) => (
                !isNullEmptyOrUndefined(item) && (
                <div>
                  {connectedItem?.itemType === 'CST' && !isNullEmptyOrUndefined(imageToken) ? (
                    <img key={item?.imagePath} className="item-image" src={item?.imagePath.concat(imageToken?.sasToken)} alt="" />
                  ) : (
                    <img key={item?.imagePath} className="item-image" src={item?.imagePath} alt="" />
                  )}
                </div>
                )
              ),
            )}
          </Carousel>
          )}
          {connectedItem?.itemType !== 'CST' ? (
            <>
              <Text className="sectionTitle item-title">{connectedItem?.itemName}</Text>
              <div className="projectItemPanel-subTitle-container sectionBottom">
                <Text className="sectionSubTitle">
                  {`${connectedItem?.paNo}  •  ${connectedItem?.itemNo}
            ${!isNullEmptyOrUndefined(connectedItem?.priceInclTax)
                    ? `  •  ${!isNullEmptyOrUndefined(connectedItem?.currency)
                      ? `${connectedItem?.currency}`
                      : ''} ${connectedItem?.priceInclTax}`
                    : ''}`}
                </Text>
                {connectedItem?.isBti === true ? (
                  <div style={{ display: 'flex', marginLeft: '8px', lineHeight: '20px' }}>
                    {'  •'}
                    <BtiComponent ml />
                  </div>
                ) : null}
              </div>
              {!isNullEmptyOrUndefined(connectedItem?.deadlineDate) ? (
                <div className="flex-row sectionBottom">
                  <Text className="sectionSubTitle">Deadline</Text>
                  <Text className="item-date">
                    {connectedItem?.deadlineDate}
                  </Text>
                </div>
              ) : null }
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionMetric"
                  title={textDimensionMetric}
                  open
                >
                  {connectedItem?.metricMeasureList?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value),
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsMaterial"
                  title={textMaterial}
                >
                  {connectedItem?.materialMeasureList?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value, 'mat'),
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion>
                <AccordionItem
                  id="itemDetailsDimensionImperial"
                  title={textDimensionImperial}
                >
                  {connectedItem?.imperialMeasureList?.map(
                    (val:any) => renderAccordionItems(val.typeName, val.value),
                  )}
                </AccordionItem>
              </Accordion>
            </>
          )
            : (
              <>
                <div className="pipCustomNewsContainer">
                  <div className="pipCustomNewsDetailWrapper">
                    {renderTabItems(pipItemNo, connectedItem?.itemNo)}
                    {renderTabItems(pipPrice, !isNullEmptyOrUndefined(connectedItem?.priceInclTax) ? connectedItem?.priceInclTax : `${0} ${connectedItem?.currency}`)}
                    {renderTabItems(pipHfb, connectedItem?.hfbNo)}
                    {renderTabItems(pipPa, connectedItem?.paNo, true)}
                    {connectedItem?.isBti === true ? (
                      <BtiComponent />
                    ) : null}
                  </div>
                  <div className="pipNoticeWrapper">
                    <SSRIcon className="pipNoticeIcon" paths={IcNotice} />
                    <Text className="pipNoticeText">
                      {pipInfoText}
                    </Text>
                  </div>
                  <div className="itemDetailSectionWrapper pipMidWrapper">
                    {!isNullEmptyOrUndefined(connectedItem?.piaFactsLink) ? (
                      <div className="pipSection">
                        <Text className="pipLabel">{pipReferenceUrl}</Text>
                        <div className="pipUrl">
                          <a href={getFullUrl(connectedItem?.piaFactsLink)} target="_blank" rel="noopener noreferrer">
                            {connectedItem?.piaFactsLink}
                          </a>
                        </div>
                      </div>
                    )
                      : null}
                  </div>
                </div>
                {!isNullEmptyOrUndefined(connectedItem?.comment) ? (
                  <Accordion>
                    <AccordionItem
                      id="itemDetailsComments"
                      title={comments}
                      open
                    >
                      <Text className="itemDetails-measure-value">{connectedItem?.comment ? connectedItem?.comment : ''}</Text>
                    </AccordionItem>
                  </Accordion>
                ) : null}
              </>
            )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default ProjectItemPanel;
