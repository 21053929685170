/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from '@ingka/text';
import { Divider } from 'antd';
import React from 'react';
import { useTranslate } from '../../../../common/services/translationService.js';
import './CustomNewsDetailTab.css';

interface CustomNewsDetailsTabProps {
  detailsTab: any;
  itemsTab: any;
  isPreview?: boolean;
}

const CustomNewsDetailsTab = ({
  detailsTab, itemsTab, isPreview,
}: CustomNewsDetailsTabProps) => {
  const cuoHfb = useTranslate('customUnit.overview.hfb');
  const cuoPra = useTranslate('customUnit.overview.pra');
  const cuoPa = useTranslate('customUnit.overview.pa');
  const cuoStatus = useTranslate('customUnit.overview.status');
  const cuoROC = useTranslate('customUnit.overview.roc');
  const cuoPriceClass = useTranslate('customUnit.overview.priceClass');
  const cuoPrice = useTranslate('customUnit.overview.price');

  const totalPrice = () => {
    let total = 0;
    itemsTab?.importedItems?.forEach((item: any) => { total += item.totalPricePerQuantity; });
    return total?.toFixed(2);
  };

  const renderTabItems = (title: string, data: string) => (
    <>
      <div className="cuoSection">
        <Text className="cuoLabel">{title}</Text>
        <Text className="cuoValue">{data}</Text>
      </div>
      {title !== cuoPriceClass ? <Divider type="vertical" /> : null}
    </>
  );

  return (
    <>
      {renderTabItems(cuoPrice, `${totalPrice()} EUR`)}
      {renderTabItems(cuoHfb, detailsTab?.Hfb)}
      {renderTabItems(cuoPra, detailsTab?.Pra)}
      {renderTabItems(cuoPa, detailsTab?.Pa)}
      {renderTabItems(
        cuoStatus,
        isPreview === true ? detailsTab?.unitState?.key : detailsTab?.unitState,
      )}
      {renderTabItems(cuoROC, detailsTab?.roc?.key)}
      {renderTabItems(cuoPriceClass, detailsTab?.priceClass?.key)}
    </>
  );
};

CustomNewsDetailsTab.defaultProps = {
  isPreview: false,
};

export default CustomNewsDetailsTab;
