/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Pill from '@ingka/pill';
import Button from '@ingka/button';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import SortUpIcon from '@ingka/ssr-icon/paths/sort-ascending';
import SortDownIcon from '@ingka/ssr-icon/paths/sort-descending';
import LinkOut from '@ingka/ssr-icon/paths/link-out';
import {
  Input, Menu, Checkbox, Popover, Table,
  TreeSelect, TableProps, ConfigProvider,
} from 'antd';
import {
  SorterResult, SortOrder,
} from 'antd/lib/table/interface';
import type { ColumnsType } from 'antd/es/table';
import SSRIcon from '@ingka/ssr-icon';
import Search from '@ingka/search';
import IcItems from '@ingka/ssr-icon/paths/copy';
import _ from 'lodash';
import HoverDialog from './HoverDialog';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import './Items.css';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  createProj, prevItems, prevProj, setLoader,
  setNewItemSelected,
} from '../../../../store/reducers/create-proj-reducer';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import {
  capitalizeFirstLetter, isIos, isRu, projectType, userRu,
  isNullEmptyOrUndefined,
  isNullEmptyUndefinedOrZero,
  userRefRu,
  checkIsCountrySpecific,
} from '../../../../common/services/commonService.js';
import Pagination from '../../../../common/components/pagination/Pagination';
import { projStatus, appRoutes } from '../../../../common/constants/constant';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import CsvImportDialog from './csvImportDialog/CsvImportDialog';
import CsvInvalidSidePanel from './csvInvalidSidePanel/CsvInvalidSidePanel';
import CloseModal from '../../../../common/components/closeModal/CloseModal';
import CsvStatusBar from './csvStatusBar/CsvStatusBar';
import IoSAddedItems from './IoSAddedItems/IoSAddedItems';
import { offlineData } from '../../../../common/configs/offlineData';

export interface Item {
  key: React.Key;
  itemName: string;
  itemType: string;
  displayPrice: string;
  itemEnglishName: string;
  paNo: string;
  state: string;
  turnOver: string;
  quantity: string;
  itemImage: string;
  itemCount: number;
  serialId: number;
  piaFactsLink: string;
  comparisonCount: number;
  itemNo: string;
  isHighLighted: boolean;
  isBti: boolean;
  ikeaMultiPack: number;
  isActive?:boolean;
  isRuItem?:number;
  itemState?:string;
}

export interface HFB {
  codeName: string,
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  items?: HFB[];
}

interface ROC {
  rocNo: string,
  key: React.Key,
  rocName: string,
}

interface PC {
  pcNo: string,
  key: React.Key,
  pcName: string,
}

interface SP {
  spNo: string,
  key: React.Key,
  spName: string,
}

const fetchUserRu = () => userRu();

const fetchProjectType = () => projectType();

export var searchData: any = {
  ItemName: '',
  ItemNo: '',
  ItemNoList: [],
  ItemTypeFilter: [],
  HfbId: [],
  PraId: [],
  PaId: [],
  RocNos: [],
  SpNo: [],
  PcNo: [],
  StartsWith: '0',
  NoOfItems: null,
  SortEntity: 'ItemName',
  TypeOfSort: '',
  IsCountrySpecific: checkIsCountrySpecific(),
  ProjectType: fetchProjectType(),
  UnitCode: fetchUserRu(),
  IsSalePriceRequired: false,
};

const { SHOW_PARENT } = TreeSelect;

export const HFBtree = ({
  projectData, isHFBOpened, dropdownClick, onCheckboxChange, defaultVal, customClass,
}: {
  projectData: HFB[],
  isHFBOpened: boolean,
  dropdownClick: (isOpen: boolean, type: string) => void,
  onCheckboxChange: (val: [], type: string) => void,
  defaultVal: [],
  customClass?: string,
}) => (
  <div className={`hfbFieldContainer ${customClass}`}>
    <TreeSelect
      treeCheckable
      treeData={projectData}
      showCheckedStrategy={SHOW_PARENT}
      fieldNames={{
        label: 'codeName',
        value: 'id',
        children: 'items',
      }}
      style={{
        width: '160px',
      }}
      defaultValue={defaultVal}
      value={defaultVal}
      suffixIcon={isHFBOpened ? <SSRIcon paths={IcUpArrow} /> : <SSRIcon paths={IcDownArrow} />}
      onDropdownVisibleChange={(val: any) => dropdownClick(val, 'hfb')}
      virtual={false}
      onChange={(val) => onCheckboxChange(val, 'hfb')}
      switcherIcon={<SSRIcon paths={IcRightArrow} />}
      popupClassName="itemsHfbTree"
    />
    <span className="hfbPlaceholder">{useTranslate('tab.items.hfbPlaceholder')}</span>
  </div>
);

HFBtree.defaultProps = {
  customClass: '',
};

const Items = (props: any) => {
  const [paginationItemCount, setPaginationItemCount] = useState<number>(0);
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [isItemOpened, setIsItemOpened] = useState(false);
  const [isRocOpened, setIsRocOpened] = useState(false);
  const [isPriceOpened, setIsPriceOpened] = useState(false);
  const [isCountrySpecific, setIsCountrySpecific] = useState(false);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [rocList, setRocList] = useState([]);
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [iosItemsList, setIosItemsList] = useState<Item[]>([]);
  const [ruItemsList, setRuItemsList] = useState<Item[]>([]);
  const [searchPayload, setSearchPayload] = useState(searchData);
  const [priceClass, setPriceClass] = useState([]);
  const [strategicPricing, setStrategicPricing] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [openCsvUploadModal, setOpenCsvUploadModal] = useState<boolean>(false);
  const [openCsvCloseModal, setOpenCsvCloseModal] = useState<boolean>(false);
  const [openCsvInvalidSidePanel, setOpenCsvInvalidSidePanel] = useState<boolean>(false);
  const [showCsvDetailPanel, setShowCsvDetailPanel] = useState<boolean>(false);
  const [showFilterSection, setShowFilterSection] = useState<boolean>(true);
  const [replaceFlag, setReplaceFlag] = useState<boolean>(false);
  const [importedItems, setImportedItems] = useState<any>([]);
  const [csvDuplicateItems, setCsvDuplicateItems] = useState<any>([]);
  const [csvInvalidFormats, setCsvInvalidFormats] = useState<any>([]);
  const [csvListTotalCount, setCsvListTotalCount] = useState<number>(0);
  const [failedImports, setFailedImports] = useState<any>([]);
  const [successfulImports, setSuccessfulImports] = useState<any>([]);
  const [tableTopMargin, setTableTopMargin] = useState<any>(0);
  const [fileName, setFileName] = useState<string>('');
  const [itemOptions, setItemOptions] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    sortEntity: string, typeOfSort: SortOrder | undefined
  }>({ sortEntity: 'itemName', typeOfSort: 'ascend' });
  const didMountRef = useRef(false);
  const projectStore = useAppSelector((state) => state?.createProjReducer?.createProjData);
  const prevProjectStore = useAppSelector((state) => state?.createProjReducer?.prevProjData);
  const dispatch = useAppDispatch();
  const { post, get } = useCallApi();
  const addAllAlbel = useTranslate('tab.items.add_all');
  const ITEM_TYPE = useTranslate('tab.items.item_type');
  const ROC = useTranslate('tab.items.roc');
  const PRICE_LEVEL = useTranslate('tab.items.price_level');
  const COUNTRY_SPECIFIC = useTranslate('tab.items.country_specific');
  const SEARCH_ITEMS = useTranslate('tab.items.search_items');
  const isEditProject = props?.isEditProject;
  const isNewProject = props?.isNewProject;
  const [searchInput, setSearchInput] = useState('');
  const [clearState, setClearState] = useState(false);
  const [imageToken, setImageToken] = useState<any>();

  const selectedItemsInStore: Item[] = projectStore?.itemsTab?.selectedItems || [];
  const prevSelectedItemsInStore: Item[] = prevProjectStore?.itemsTab?.selectedItems || [];

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const setRocData = (data: []) => {
    const updatedRocData: any = [];
    data.map((item: ROC, index) => (updatedRocData.push(
      { key: index, value: item.rocNo, label: item.rocName },
    )));
    setRocList(updatedRocData);
  };

  const isAcceptedProj = () => {
    if (props.projtType === projStatus.accepted) {
      return true;
    }
    if (isRu() && isEditProject && props.projDetails?.hasParentProject) {
      return true;
    }
    return false;
  };

  const isDraftProj = () => (props.projtType === projStatus.draft);

  const setPriceLevelData = (data: any) => {
    const updatedPlData: any = [];
    const updatedSlData: any = [];
    data?.priceClass.map((item: PC, index: number) => (updatedPlData.push(
      { key: index, value: item.pcNo, label: item.pcName },
    )));
    setPriceClass(updatedPlData);
    data?.strategicPricing.map((item: SP, index: number) => (updatedSlData.push(
      { key: index, value: item.spNo, label: item.spName },
    )));
    setStrategicPricing(updatedSlData);
  };

  const getHFB = () => {
    get('business-structure', setHFBdata);
  };

  const getROC = () => {
    get('rocs', setRocData);
  };

  const getItemType = () => {
    offlineData?.getItem('enumData').then((data: any) => {
      const versionObj = data?.find((item: any) => item.key === 'ITEM_TYPE_LIST');
      const arr = versionObj.value.split('|');
      setItemOptions(arr);
    });
  };

  const getPriceLevel = () => {
    get('price-level', setPriceLevelData);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows?: any) => {
    if (isDraftProj() || isEditProject) {
      let draftProjData;
      const selectedData: any = [...selectedItems];
      selectedRows?.forEach((newItem: any) => {
        selectedData?.forEach((oldItem: any, index: any) => {
          if (newItem?.itemNo === oldItem?.itemNo) {
            selectedData.splice(index, 1);
          }
        });
      });
      if (isDraftProj()) {
        selectedRows && selectedRows?.length !== 0 && selectedRows?.map((val:any) => {
          if (!isNullEmptyOrUndefined(val) && isNullEmptyOrUndefined(val.isActive)) { val.isActive = true; }
        });
      } else {
        selectedRows && selectedRows?.length !== 0 && selectedRows?.map((val:any) => {
          if (!isNullEmptyOrUndefined(val) && isNullEmptyOrUndefined(val.isActive) && isNullEmptyOrUndefined(val.isRuItem)) { val.isRuItem = 1; }
        });
      }
      const draftSelectedItems: any = [...selectedRows, ...selectedData]
        .filter((item: any) => item !== undefined);
      const combinedSelectedItems = draftSelectedItems
        .filter((item: any) => newSelectedRowKeys.some((itemKey) => itemKey === item?.itemNo));
      if (combinedSelectedItems.length <= 150) {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedItems(combinedSelectedItems);
        if (isRu()) {
          const newList = selectedRows?.map((val: any) => val?.itemNo)?.filter((val: any) => !isNullEmptyOrUndefined(val));
          // const removedItem = selectedRowKeys?.filter((val :any) => !newList.includes(val));
          let updatedSuItemList = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
          let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
          const unselectedItem = selectedRowKeys.filter((val:any) => !newSelectedRowKeys.some((i) => i === val));
          const newSelectedItem = newList?.filter((val :any) => !selectedRowKeys.includes(val));
          // const addedOrRemovedItem = !isNullEmptyOrUndefined(newSelectedItem) ? newSelectedItem : unselectedItem;
          if (unselectedItem?.length !== 0) {
            updatedSuItemList.forEach((o :any) => {
              o.SuItemList = o.SuItemList?.filter((s: any) => s.ItemNo !== unselectedItem[0]);
            });
          }

          if (newSelectedItem?.length !== 0) {
            const newSelRowData = selectedRows?.find((val:any) => val?.itemNo === newSelectedItem[0]);
            dispatch(setNewItemSelected([newSelRowData]));
            updatedSuItemList.forEach((o :any) => {
              o.SuItemList.push({
                ItemNo: newSelRowData.itemNo,
                ItemType: newSelRowData.itemType,
              });
            });
          }
          updatedSuItemList = updatedSuItemList?.filter((s: any) => s?.SuItemList?.length !== 0);
          updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemList?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
          draftProjData = ({
            ...projectStore,
            itemsTab: { selectedItems: combinedSelectedItems },
            selectedSuItems: updatedSuItemList,
            suTab: { selectedSu: updatedSuList },
          });
        } else {
          draftProjData = ({
            ...projectStore,
            itemsTab: { selectedItems: combinedSelectedItems },
          });
        }
        dispatch(createProj(draftProjData));
        dispatch(prevItems(combinedSelectedItems));
      }
    } else {
      if (newSelectedRowKeys.length <= 150) {
        setSelectedRowKeys(newSelectedRowKeys);
      }
      if (selectedRows.length <= 150) {
        let projData:any = {};
        if (isAcceptedProj()) {
          const isAcceptedItems = selectedRows?.filter((item: any) => !isNullEmptyOrUndefined(item));
          const filterResp = isAcceptedItems.filter((val:any) => !ruItemsList.some((i) => i.itemNo === val.itemNo) && val.isActive !== true);
          const unselectedItem = selectedRowKeys.filter((val:any) => !newSelectedRowKeys.some((i) => i === val));
          const currentRuSelection = unselectedItem.length !== 0 ? ruItemsList.filter((val) => val.itemNo !== unselectedItem[0]) : ruItemsList;
          setRuItemsList(currentRuSelection);
          isAcceptedItems?.map((val:any) => (isNullEmptyOrUndefined(val.isRuItem) ? val.isRuItem = 1 : null));
          const iosActiveItems = iosItemsList.filter((val) => val.isActive === true);
          if (isRu()) {
            const newList = selectedRows?.map((val: any) => val?.itemNo)?.filter((val: any) => !isNullEmptyOrUndefined(val));
            const newSelectedItem = newList?.filter((val :any) => !selectedRowKeys.includes(val));
            let updatedSuItemList = JSON.parse(JSON.stringify(projectStore.selectedSuItems));
            let updatedSuList = JSON.parse(JSON.stringify(projectStore.suTab.selectedSu));
            // const addedOrRemovedItem = !isNullEmptyOrUndefined(newSelectedItem) ? newSelectedItem : unselectedItem;
            if (unselectedItem?.length !== 0) {
              updatedSuItemList.forEach((o :any) => {
                o.SuItemList = o.SuItemList?.filter((s: any) => s.ItemNo !== unselectedItem[0]);
              });
            }
            if (newSelectedItem?.length !== 0) {
              const newSelRowData = selectedRows?.find((val:any) => val?.itemNo === newSelectedItem[0]);
              dispatch(setNewItemSelected([newSelRowData]));
              updatedSuItemList.forEach((o :any) => {
                o.SuItemList.push({
                  ItemNo: newSelRowData.itemNo,
                  ItemType: newSelRowData.itemType,
                });
              });
            }
            updatedSuItemList = updatedSuItemList?.filter((s: any) => s?.SuItemList?.length !== 0);
            updatedSuList = updatedSuList?.filter((item: any) => updatedSuItemList?.some((itemKey: any) => itemKey?.SuCode === item?.unitCode));
            projData = ({
              ...projectStore,
              itemsTab: { selectedItems: [...iosActiveItems, ...filterResp, ...currentRuSelection] },
              selectedSuItems: updatedSuItemList,
              suTab: { selectedSu: updatedSuList },
            });
          } else projData = ({ ...projectStore, itemsTab: { selectedItems: [...iosActiveItems, ...filterResp, ...currentRuSelection] } });
        } else projData = ({ ...projectStore, itemsTab: { selectedItems: selectedRows } });
        setSelectedItems(selectedRows);
        dispatch(createProj(projData));
        dispatch(prevItems(selectedRows));
      }
    }
  };

  const onAddAllClick = () => {
    const selectedKeys: React.Key[] = [...selectedRowKeys];
    const selectedData: any = [...selectedItems];
    searchResults.forEach((item) => {
      if (!selectedKeys.includes(item.itemNo)) {
        selectedKeys.push(item.itemNo);
        selectedData.push(item);
      }
    });
    onSelectChange(selectedKeys, selectedData);
  };

  useEffect(() => {
    getSasToken();
    getHFB();
    getROC();
    getItemType();
    getPriceLevel();
    if (isAcceptedProj() === true) {
      const acceptedProjData = ({
        ...projectStore,
        itemsTab: { selectedItems: props?.itemDetail },
      });
      dispatch(createProj(acceptedProjData));
      dispatch(prevProj(acceptedProjData));
      const iosSelectedItems = props?.itemDetails?.filter((item: any) => item.isRuItem !== 1);
      setIosItemsList(iosSelectedItems);
      const ruSelectedItems = props?.itemDetails?.filter((item: any) => item.isRuItem === 1);
      setRuItemsList(ruSelectedItems || []);
    }
  }, []);

  const setTableMargin = () => {
    const pillsHeight = document?.getElementById('itemsHeaderWrapperDiv')?.offsetHeight || 0;
    setTableTopMargin(pillsHeight);
  };

  useEffect(() => {
    if (importedItems.length !== 0 && searchResults.length !== 0) {
      const failedImport = importedItems.filter((val:string) => !searchResults.some((i) => i.itemNo.replace(/\./g, '') === val));
      const successfulImport = importedItems.filter((val:string) => searchResults.some((i) => i.itemNo.replace(/\./g, '') === val));
      setFailedImports(failedImport);
      setSuccessfulImports(successfulImport);
      setShowCsvDetailPanel(true);
    }
    setTableMargin();
  }, [searchResults]);

  useEffect(() => {
    setTableMargin();
  }, [showCsvDetailPanel]);

  useEffect(() => {
    if (_.isEqual(searchPayload, searchData) && !isAcceptedProj()) {
      setSearchResults([]);
    }
  }, [searchPayload]);

  useEffect(() => {
    if (selectedItemsInStore?.length !== selectedItems?.length) {
      const newSelectedKeys: React.Key[] = [];
      if (isAcceptedProj()) {
        const filteredItems = prevSelectedItemsInStore?.filter((item: any) => item.isRuItem !== 1);
        setIosItemsList(filteredItems);
        const ruSelectedItem = selectedItemsInStore?.filter((item: any) => item.isRuItem === 1);
        setRuItemsList(ruSelectedItem || []);
      }
      selectedItemsInStore?.forEach((item) => newSelectedKeys.push(item?.itemNo));
      setSelectedRowKeys(newSelectedKeys);
      setSelectedItems(selectedItemsInStore);
    }
  }, [JSON.parse(JSON.stringify(selectedItemsInStore))]);

  useEffect(() => {
    if (props?.projDetails?.hasParentProject && (JSON.stringify(selectedItemsInStore) !== JSON.stringify(selectedItems))) {
      setSelectedItems(selectedItemsInStore);
    }
  }, [selectedItemsInStore]);

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  const setFilterShow = () => {
    const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(searchData);
    return checkFilterExist;
  };

  const searchItems = (searchInputData?: any, isImport?: boolean, importedArray?: any) => {
    const searchParams = !isNullEmptyOrUndefined(searchInputData) ? searchInputData : searchPayload;
    const checkFilterExist = JSON.stringify(searchParams) !== JSON.stringify(searchData);
    setHasFilter(setFilterShow());
    isNullEmptyOrUndefined(searchInputData) && setSearchResults([]);
    searchData.IsCountrySpecific = isCountrySpecific;
    if (checkFilterExist) {
      toggleLoader(true);
      post(
        'search-items',
        searchParams,
        (response: Item[]) => {
          const filterRes = response.filter((val:any) => !iosItemsList.some((i) => i.itemNo === val.itemNo));
          setSearchResults(!isNullEmptyOrUndefined(searchInputData) ? [...searchResults, ...filterRes] : filterRes);
          toggleLoader(false);
          if (!isNullEmptyUndefinedOrZero(importedArray) && isImport === true && response.length === 0) {
            setSuccessfulImports([]);
            setFailedImports(importedArray);
            setShowCsvDetailPanel(true);
          }
        },
        (error: any) => {
          toggleLoader(false);
          setSearchResults([]);
          if (!isNullEmptyUndefinedOrZero(importedArray) && isImport === true) {
            setSuccessfulImports([]);
            setFailedImports(importedArray);
            setShowCsvDetailPanel(true);
          }
        },
      );
    } else {
      toggleLoader(false);
      setSearchResults([]);
    }
    setTriggerShowmore(false);
  };

  const setCommonSearchParams = (searchDat: any, isImport?: boolean, importedArray?: any) => {
    let searchApiData: any = searchDat;
    isIos() === false ? searchApiData = (
      { ...searchApiData, UnitCode: userRefRu() }) : null;
    isIos() === false ? searchData = (
      { ...searchData, UnitCode: userRefRu() }) : null;
    searchApiData = (
      { ...searchApiData, ProjectType: projectType() });
    searchData = (
      { ...searchData, ProjectType: projectType() });
    searchApiData = ({ ...searchApiData, IsCountrySpecific: isCountrySpecific });
    searchData = ({ ...searchData, IsCountrySpecific: isCountrySpecific });
    setSearchPayload(searchApiData);
    isImport === true && searchItems({ ...searchApiData }, isImport, importedArray);
  };

  useEffect(() => {
    if ((JSON.stringify(searchPayload) !== JSON.stringify(searchData))) {
      let searchApiData: any = searchPayload;
      searchApiData = ({ ...searchApiData, IsCountrySpecific: isCountrySpecific });
      setCommonSearchParams(searchApiData);
    }
  }, [isCountrySpecific]);

  const getSortOrder = (dataIndex: string) => {
    if (sortConfig.sortEntity === dataIndex) {
      return sortConfig.typeOfSort;
    }
    return null;
  };

  const renderTableTitle = (title: string, dataIndex: string) => {
    const isActive = dataIndex === sortConfig.sortEntity;
    return (
      isActive ? (
        <div>
          {title}
          {sortConfig.typeOfSort === 'ascend' ? (
            <SSRIcon className="sortIcon" paths={SortUpIcon} />
          ) : (
            <SSRIcon className="sortIcon" paths={SortDownIcon} />
          )}
        </div>
      ) : (
        <div>{title}</div>
      )
    );
  };

  const navigateToFC = (val: any) => {
    window.open(`${appRoutes.FC}?item=${val?.itemNo}`, '_blank', 'noreferrer');
  };

  const renderComparisonCount = (text: any, value: any) => (
    text > 0 ? (
      <div className="table-number itemPointer itemsComparisonCount">
        {text}
        <SSRIcon className="table-image-right" paths={LinkOut} onClick={() => navigateToFC(value)} />
      </div>
    ) : (
      <div className="table-number">
        {text}
      </div>
    )
  );

  const columns: ColumnsType<Item> = [
    {
      key: 1,
      title: renderTableTitle(useTranslate('tab.items.state'), 'state'),
      dataIndex: 'itemState',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('state'),
      width: 50,
      render: (text: any, record) => <div>{record.itemState || record.state}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: renderTableTitle(useTranslate('tab.items.item_name'), 'itemName'),
      dataIndex: 'itemName',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemName'),
      width: 130,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: renderTableTitle(useTranslate('tab.items.item'), 'itemNo'),
      dataIndex: 'itemNo',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemNo'),
      width: 50,
      className: 'table-number',
      render: (text: any) => <div className="table-number itemNo-common-design">{text}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: renderTableTitle(useTranslate('tab.items.type'), 'itemType'),
      dataIndex: 'itemType',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemType'),
      width: 45,
      className: 'table-header-padding compLeftPadding',
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: useTranslate('tab.items.image'),
      dataIndex: 'itemImage',
      className: 'table-header-padding',
      width: 25,
      render: (text: any, value: any) => <HoverDialog piaDetails={value} imageToken={imageToken} />,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 6,
      title: renderTableTitle(useTranslate('tab.items.comp'), 'comparisonCount'),
      dataIndex: 'comparisonCount',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('comparisonCount'),
      width: 40,
      className: 'table-number compRightPadding',
      render: (text: any, value: any) => renderComparisonCount(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  const handleTableChange: TableProps<Item>['onChange'] = (pagination, filters, sorter, extra) => {
    const sortEntity = ((sorter as SorterResult<Item>).field) as string;
    const sortTypeParam = (sorter as SorterResult<Item>).order;
    const typeOfSort = (!sortTypeParam || sortTypeParam === 'ascend') ? 'Ascending' : 'Descending';
    setSortConfig({ sortEntity, typeOfSort: (sorter as SorterResult<Item>).order || 'ascend' });
    setSearchResults([]);
    setSearchPayload({
      ...searchPayload,
      SortEntity: capitalizeFirstLetter(sortEntity),
      TypeOfSort: typeOfSort,
      StartsWith: '0',
    });
    searchItems();
  };

  const onCheckboxChange = (selection: [], type: string) => {
    let searchApiData: any = searchPayload;
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    switch (type) {
      case 'hfb':
        setSelectedHFBs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                HFBData.push(val);
                break;
              case 3:
                PRAData.push(val);
                break;
              case 4:
                PAData.push(val);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          HFBData = []; PRAData = []; PAData = [];
        }
        searchApiData = ({
          ...searchPayload, HfbId: HFBData, PraId: PRAData, PaId: PAData,
        });
        break;
      case 'item':
        searchApiData = ({ ...searchPayload, ItemTypeFilter: selection });
        break;
      case 'roc':
        searchApiData = ({ ...searchPayload, RocNos: selection });
        break;
      case 'pc':
        searchApiData = ({ ...searchPayload, PcNo: selection });
        break;
      case 'sp':
        searchApiData = ({ ...searchPayload, SpNo: selection });
        break;
      default:
        break;
    }
    searchApiData.NoOfItems = null;
    searchApiData.StartsWith = '0';
    setPaginationItemCount(0);
    // check filter selection
    const filterSelected = (!isNullEmptyOrUndefined(searchApiData.HfbId)
    || !isNullEmptyOrUndefined(searchApiData.PraId)
    || !isNullEmptyOrUndefined(searchApiData.PaId)
    || !isNullEmptyOrUndefined(searchApiData.ItemTypeFilter)
    || !isNullEmptyOrUndefined(searchApiData.RocNos)
    || !isNullEmptyOrUndefined(searchApiData.PcNo)
    || !isNullEmptyOrUndefined(searchApiData.SpNo)
    || !isNullEmptyOrUndefined(searchApiData.ItemNo)
    || !isNullEmptyOrUndefined(searchApiData.ItemName));

    // check prerequiste
    if (!filterSelected) {
      setSearchResults([]);
      setSearchPayload(searchData);
      setSelectedHFBs([]);
    } else {
      setCommonSearchParams(searchApiData);
    }
  };

  const dropdownClick = (isOpen: boolean, type: string) => {
    switch (type) {
      case 'hfb':
        setIsHFBOpened(isOpen);
        break;
      case 'item':
        setIsItemOpened(isOpen);
        break;
      case 'roc':
        setIsRocOpened(isOpen);
        break;
      case 'price':
        setIsPriceOpened(isOpen);
        break;
      default:
        break;
    }
  };

  const onSearchChange = (e: any, data: any) => {
    let searchApiData: any = searchPayload;
    const searchTerm = data.value || null;
    setSearchInput(searchTerm);
    if (searchTerm === null) {
      if (searchApiData === searchData) {
        setSearchResults([]);
        searchApiData = searchData;
      } else {
        setSearchResults([]);
        searchApiData = {
          ...searchPayload,
          ItemName: '',
          ItemNo: '',
        };
      }
    } else {
      let searchCriteria = { ItemName: '', ItemNo: '' };
      const enteredInput = searchTerm.trim()
        .replace(/\./g, '')
        .replace(/-/g, '');
      if (/^CST\d/.test(enteredInput)) {
        searchCriteria = { ItemName: '', ItemNo: enteredInput };
      } else if (!isNaN(enteredInput) && enteredInput.length === 8) {
        searchCriteria = { ItemName: '', ItemNo: searchTerm.trim() };
      } else {
        searchCriteria = { ItemName: searchTerm.trim(), ItemNo: '' };
      }
      searchApiData = { ...searchPayload, ...searchCriteria };
      setSearchResults([]);
    }
    searchApiData = { ...searchApiData, StartsWith: '0' };
    setPaginationItemCount(0);
    setCommonSearchParams(searchApiData);
  };

  const handleSearch = () => {
    if (searchInput) {
      // If searchTerm is present, trigger onSearchChange
      onSearchChange(null, { value: searchInput });
    }
    // Trigger searchItems
    searchItems();
  };

  const onImportSearch = (data: any) => {
    let searchApiData: any = searchPayload;
    const searchCriteria = { ItemNoList: data };
    searchApiData = { ...searchPayload, ...searchCriteria };
    searchApiData = { ...searchApiData, StartsWith: '0' };
    setPaginationItemCount(0);
    setCommonSearchParams(searchApiData, true, data);
  };

  const priceMenu = (
    <Menu>
      <Menu.ItemGroup title={useTranslate('tab.items.price_class')}>
        <Menu.Item>
          <Checkbox.Group options={priceClass} value={searchPayload?.PcNo} onChange={(val: any) => onCheckboxChange(val, 'pc')} />
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title={useTranslate('tab.items.strategic_pricing')}>
        <Menu.Item>
          <Checkbox.Group options={strategicPricing} value={searchPayload?.SpNo} onChange={(val: any) => onCheckboxChange(val, 'sp')} />
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const itemTypeMenu = (
    <Checkbox.Group options={itemOptions} value={searchPayload?.ItemTypeFilter} onChange={(val: any) => onCheckboxChange(val, 'item')} />
  );
  const rocMenu = (
    <Checkbox.Group options={rocList} value={searchPayload?.RocNos} onChange={(val: any) => onCheckboxChange(val, 'roc')} />
  );

  const countrySpecificHandler = () => setIsCountrySpecific(!isCountrySpecific);

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
    columnTitle: useTranslate('tab.items.select'),
    columnWidth: 25,
    onChange: onSelectChange,
    scrollToFirstRowOnChange: true,
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    if (paginationItemCount > 0) {
      // setSearchPayload({ ...searchPayload, StartsWith: (paginationItemCount * 25).toString() });
      searchItems({ ...searchPayload, StartsWith: (paginationItemCount * 25).toString() });
    }
  }, [paginationItemCount]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const renderPagination = () => {
    const count = searchResults.length;
    const totalCount = searchResults[0].itemCount;
    return (
      <Pagination
        count={count}
        isLoading={isLoading}
        totalCount={totalCount}
        showMoreContent={showMoreContent}
      />
    );
  };

  const unselectedItemCount = () => {
    let unselectedRows = [];
    unselectedRows = searchResults?.filter((item) => {
      const isItemSelected = selectedItems?.some((selectedItem) => {
        if (!selectedItem || !selectedItem.itemNo) return false;
        return selectedItem.itemNo === item.itemNo;
      });
      return !isItemSelected;
    });

    return unselectedRows.length || 0;
  };

  const selectedItemCount = () => {
    let selectedRows = [];
    selectedRows = searchResults?.filter((item) => {
      const isItemSelected = selectedItems?.some((selectedItem) => {
        if (!selectedItem || !selectedItem.itemNo) return false;
        return selectedItem.itemNo === item.itemNo;
      });
      return isItemSelected;
    });
    return selectedRows.length || 0;
  };

  const clearCsvInfo = () => {
    setSearchResults([]);
    setPaginationItemCount(0);
    setSearchPayload(searchData);
    setFailedImports([]);
    setSuccessfulImports([]);
    setImportedItems([]);
    setCsvDuplicateItems([]);
    setCsvInvalidFormats([]);
    setCsvListTotalCount(0);
    setShowCsvDetailPanel(false);
    setShowFilterSection(true);
    setSelectedHFBs([]);
    setSearchInput('');
    setHasFilter(false);
    setReplaceFlag(true);
  };

  const handleSubmitModal = (type?:number) => {
    if (type === 2) {
      clearCsvInfo();
    }
    setOpenCsvCloseModal(false);
  };

  const openCsvClose = () => {
    const newItemSelected = selectedItemsInStore
      .filter((item: any) => successfulImports.some((itemKey:any) => itemKey === item.itemNo.replace(/\./g, '')));
    if (newItemSelected.length === 0 && successfulImports.length !== 0) {
      setOpenCsvCloseModal(true);
    } else {
      setOpenCsvCloseModal(false);
      clearCsvInfo();
    }
  };

  const onImport = (selectedArray:any[], duplicateArray:any[], invalidArray:any[], name:string, totalCount: number) => {
    setShowFilterSection(false);
    clearCsvInfo();
    setClearState(true);
    setOpenCsvUploadModal(false);
    setFileName(name);
    setCsvDuplicateItems(duplicateArray);
    setCsvInvalidFormats(invalidArray);
    setCsvListTotalCount(totalCount);
    setSelectedItems(selectedArray);
  };

  useEffect(() => {
    if (clearState) {
      if (selectedItems.length !== 0) {
        setImportedItems(selectedItems);
        onImportSearch(selectedItems);
        setHasFilter(true);
      } else {
        setFailedImports(selectedItems);
        setSuccessfulImports([]);
        setShowCsvDetailPanel(true);
      }
      setClearState(false);
    }
  }, [clearState]);

  const getHeaderHeight = () => {
    const height = document.getElementById('itemsHeaderWrapperDiv')?.offsetHeight || 0;
    return height + 148;
  };

  useEffect(() => {
    const itemsTablePagination = document.querySelector<HTMLElement>('.paginationWrapper');
    // table onload pagination hide
    if (itemsTablePagination) {
      itemsTablePagination.style.display = 'none';
    }
  }, [searchResults]);

  useEffect(() => {
    const itemsTable = document.querySelector<HTMLElement>('.ant-table-body');
    if (itemsTable) {
      itemsTable.addEventListener('scroll', () => {
        const scrollPercentage = (itemsTable.scrollTop / (itemsTable.scrollHeight - itemsTable.clientHeight)) * 100;
        if (scrollPercentage <= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
        }
        if (scrollPercentage >= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = '';
        }
      });
    }
  }, []);

  const disableAddall = () => searchResults[0].itemCount > 150
              || searchResults[0].itemCount === selectedRowKeys.length
              || unselectedItemCount() + selectedRowKeys.length >= 150
              || selectedItemCount() >= 150
              || searchResults[0].itemCount === selectedItemCount();

  return (
    <>
      <CsvImportDialog
        isVisible={openCsvUploadModal}
        onCloseModal={() => setOpenCsvUploadModal(false)}
        replaceFlag={replaceFlag}
        onImport={onImport}
      />
      <CloseModal
        modelOpen={openCsvCloseModal}
        closeModal={handleSubmitModal}
        saveValid
        closeText={useTranslate('common.text.cancel')}
        saveText={useTranslate('csv.statusBar.closeOk')}
        title={useTranslate('csv.statusBar.closeTitle')}
        subText={useTranslate('csv.statusBar.closeSubTitle')}
      />
      <div className="items_header_wrapper" id="itemsHeaderWrapperDiv">
        {!isAcceptedProj() && (
          <>
            <CsvInvalidSidePanel
              isVisible={openCsvInvalidSidePanel}
              invalidList={failedImports}
              csvDuplicateItems={csvDuplicateItems}
              csvInvalidFormats={csvInvalidFormats}
              onClose={() => setOpenCsvInvalidSidePanel(false)}
            />
            <CsvStatusBar
              invalidListCount={[...failedImports, ...csvDuplicateItems, ...csvInvalidFormats]?.length || 0}
              fileName={fileName}
              csvListTotalCount={csvListTotalCount}
              openCsvInvalidSidePanel={() => setOpenCsvInvalidSidePanel(true)}
              openCsvUploadModal={() => {
                setReplaceFlag(false);
                setOpenCsvUploadModal(true);
              }}
              openCsvUploadModalReplace={() => {
                setReplaceFlag(true);
                setOpenCsvUploadModal(true);
              }}
              isVisible={showCsvDetailPanel}
              closeCsvDetailpanel={openCsvClose}
            />
          </>
        )}
        {showFilterSection === true ? (
          <div className="item_flexbox">
            <div className="items_filters-container">
              {HFBdata.length > 0 && (
                <HFBtree
                  projectData={HFBdata}
                  isHFBOpened={isHFBOpened}
                  dropdownClick={dropdownClick}
                  onCheckboxChange={onCheckboxChange}
                  defaultVal={selectedHFBs}
                />
              )}
              <Popover
                overlayClassName="itemsPopover"
                content={<Input.Group compact>{itemTypeMenu}</Input.Group>}
                trigger="click"
                placement="bottomLeft"
                onOpenChange={(val: any) => dropdownClick(val, 'item')}
              >
                <Pill
                  iconPosition="trailing"
                  label={ITEM_TYPE}
                  small
                  ssrIcon={isItemOpened ? IcUpArrow : IcDownArrow}
                />
              </Popover>
              <Popover
                overlayClassName="itemsPopover"
                content={<Input.Group compact>{rocMenu}</Input.Group>}
                trigger="click"
                placement="bottomLeft"
                onOpenChange={(val: any) => dropdownClick(val, 'roc')}
              >
                <Pill
                  iconPosition="trailing"
                  label={ROC}
                  small
                  ssrIcon={isRocOpened ? IcUpArrow : IcDownArrow}
                />
              </Popover>
              <Popover
                overlayClassName="itemsPopover"
                content={<Input.Group compact>{priceMenu}</Input.Group>}
                trigger="click"
                placement="bottomLeft"
                onOpenChange={(val: any) => dropdownClick(val, 'price')}
              >
                <Pill
                  iconPosition="trailing"
                  label={PRICE_LEVEL}
                  small
                  ssrIcon={isPriceOpened ? IcUpArrow : IcDownArrow}
                />
              </Popover>
              {isIos() ? (
                <Pill
                  iconPosition="trailing"
                  label={COUNTRY_SPECIFIC}
                  small
                  selected={isCountrySpecific}
                  onClick={countrySpecificHandler}
                />
              ) : null}
            </div>
            {(!showCsvDetailPanel && (isDraftProj() || isNewProject === true)) && (
              <Button
                href=""
                text="Import"
                type="primary"
                style={{ marginRight: '15px' }}
                onClick={() => {
                  toggleLoader(true);
                  setTimeout(() => {
                    setOpenCsvUploadModal(true);
                    toggleLoader(false);
                  }, 1000);
                }}
                small
              />
            )}
            <Search
              className="search_pill_items"
              id="search"
              value={searchInput}
              placeholder={SEARCH_ITEMS}
              onChange={(e, data) => onSearchChange(e, data)}
              onSearch={onSearchChange}
              onClear={() => {
                clearCsvInfo();
              }}
            />
            <Button
              className="fcSearchBtn"
              type="primary"
              text="Search"
              small
              onClick={handleSearch}
              style={{ marginLeft: '16px' }}
            />
          </div>
        ) : null}
        {hasFilter === true ? (
          <>
            <hr className="items-demo-divider__hr-horizontal" />
            <div className="items_pills-container">

              <PillsToolbar
                data={searchPayload}
                hfbData={selectedHFBs}
                setCountrySpecific={setIsCountrySpecific}
                rocList={rocList}
                priceClass={priceClass}
                strategicPricing={strategicPricing}
                onRemoveFilter={(newPayload, newHfbData) => {
                  setPaginationItemCount(0);
                  setSelectedHFBs(newHfbData);
                  setSearchPayload({ ...newPayload, StartsWith: '0' });
                  handleSearch();
                  setHasFilter(setFilterShow());
                }}
                onClearAll={() => {
                  clearCsvInfo();
                }}
              />

              {(searchResults.length > 0)
              && (
                <Button
                  className="btn_addAll"
                  href=""
                  text={`${addAllAlbel} (${searchResults[0]?.itemCount})`}
                  disabled={disableAddall()}
                  type="emphasised"
                  onClick={onAddAllClick}
                  small
                />
              )}
            </div>
          </>
        ) : null}
      </div>
      {iosItemsList?.length > 0 && isAcceptedProj() ? (
        <IoSAddedItems
          data={iosItemsList}
          hasFilter={hasFilter}
          navigateToFC={navigateToFC}
          imageToken={imageToken}
        />
      ) : null}
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText="Add Items"
          icon={IcItems}
          subText
        />
      )}
      >
        <div className="itemsHeaderWrapperDiv" style={{ height: `calc(100vh - ${getHeaderHeight()}px)` }}>
          <Table
            className={`table-striped-rows 
            ${isAcceptedProj() ? 'itemsTableAccepted' : 'itemsTable'}
            ${searchResults.length > 0 ? '' : 'itemsBodyOverflow'}`}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={searchResults}
            pagination={false}
            size="small"
            rowKey={(record: any) => record.itemNo}
            onChange={handleTableChange}
            showHeader={searchResults.length > 0}
            scroll={searchResults.length > 0 ? { y: `${isAcceptedProj() ? 'calc(100vh - 400px)' : 'calc(100vh - 280px)'}` } : { y: '' }}
            footer={undefined}
          />
          {searchResults.length > 0
          && searchResults[0].itemCount > 25 && renderPagination()}
        </div>
      </ConfigProvider>
    </>
  );
};
export default Items;
