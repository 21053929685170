/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import RadioButton from '@ingka/radio-button';
import Pill from '@ingka/pill';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import IcFilter from '@ingka/ssr-icon/paths/filters';
import {
  Menu, Checkbox, Popover, Calendar,
  TreeSelect, DatePickerProps, DatePicker, Select, Tree,
  Space, TableProps,
} from 'antd';
import {
  SorterResult, SortOrder,
} from 'antd/lib/table/interface';
import SSRIcon from '@ingka/ssr-icon';
import SearchData from '@ingka/search';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import jsdownload from 'js-file-download';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import './Filters.css';
import styles from './Filters.module.css';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  isIos, projectType, userRu, isRu, isSu,
  userData, isNullEmptyOrUndefined, capitalizeFirstLetter,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import { RetailUnit } from '../../createProject/retailUnitsTab/RetailUnits.js';
import {
  Comparison, ComparisonList, comparisonPayload, fectchUnitCode,
} from '../comparisonList/ComparisonList';
import { appRoutes } from '../../../../common/constants/constant';
import { setCompResults } from '../../../../store/reducers/find-comp-reducer';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import PageHeader from '../../../../common/components/header/Header';
import { getQualityCheckCount } from '../../../../common/services/commonApi.js';
import CloseModal from '../../../../common/components/closeModal/CloseModal';

export interface HFB {
  codeName: string,
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  items?: HFB[];
}

export interface COMP {
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  children?: COMP[];
}

export interface RUSU {
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  children?: RUSU[];
}

interface ROC {
  rocNo: string,
  key: React.Key,
  rocName: string,
}

interface PC {
  pcNo: string,
  key: React.Key,
  pcName: string,
}

interface SP {
  spNo: string,
  key: React.Key,
  spName: string,
}

interface SPR {
  key: string,
  sprKey: React.Key,
  value: string,
}

interface RSP {
  key: string,
  rspKey: React.Key,
  value: string,
}

const { SHOW_PARENT } = TreeSelect;

export const HFBtree = ({
  projectData, isHFBOpened, dropdownClick, onCheckboxChange, defaultVal, customClass,
}: {
  projectData: HFB[],
  isHFBOpened: boolean,
  dropdownClick: (isOpen: boolean, type: string) => void,
  onCheckboxChange: (val: [], type: string) => void,
  defaultVal: [],
  customClass?: string,
}) => (
  <div className={`fcHfbFieldContainer ${customClass}`}>
    <TreeSelect
      treeCheckable
      treeData={projectData}
      showCheckedStrategy={SHOW_PARENT}
      fieldNames={{
        label: 'codeName',
        value: 'id',
        children: 'items',
      }}
      style={{
        width: '160px',
      }}
      value={defaultVal}
      suffixIcon={isHFBOpened ? <SSRIcon paths={IcUpArrow} /> : <SSRIcon paths={IcDownArrow} />}
      onDropdownVisibleChange={(val: any) => dropdownClick(val, 'hfb')}
      virtual={false}
      onChange={(val) => onCheckboxChange(val, 'hfb')}
      switcherIcon={<SSRIcon paths={IcRightArrow} />}
      popupClassName="fcHfbTree"
    />
    <span className="fcHfbPlaceholder">{useTranslate('tab.items.hfbPlaceholder')}</span>
  </div>
);

export const RUSUTree = ({
  RUSUData, isRUSUOpened, dropdownClick, onCheckboxChange, defaultVal, customClass,
}: {
  RUSUData: RUSU[],
  isRUSUOpened: boolean,
  dropdownClick: (isOpen: boolean, type: string) => void,
  onCheckboxChange: (val: [], type: string) => void,
  defaultVal: [],
  customClass?: string,
}) => (
  <div className={`fcRUSUFieldContainer ${customClass}`}>
    <TreeSelect
      treeCheckable
      treeData={RUSUData}
      showCheckedStrategy={SHOW_PARENT}
      fieldNames={{
        label: 'text',
        value: 'id',
        children: 'children',
      }}
      style={{
        width: '120px',
      }}
      value={defaultVal}
      suffixIcon={isRUSUOpened ? <SSRIcon paths={IcUpArrow} /> : <SSRIcon paths={IcDownArrow} />}
      onDropdownVisibleChange={(val: any) => dropdownClick(val, 'rusu')}
      virtual={false}
      onChange={(val) => onCheckboxChange(val, 'rusu')}
      switcherIcon={<SSRIcon paths={IcRightArrow} />}
      popupClassName="fcRuSuTree"
    />
    <span className="fcRUSUPlaceholder">{useTranslate('find.comp.ruSuPlaceholder')}</span>
  </div>
);

HFBtree.defaultProps = {
  customClass: '',
};

RUSUTree.defaultProps = {
  customClass: '',
};

const Filters = () => {
  dayjs.extend(isSameOrBefore);
  const location: any = useLocation();
  const userDetails: any = {
    UnitCode: fectchUnitCode(),
    ProjectType: projectType(),
    CurrentUserId: userData()?.userId,
    IsSuperAdmin: userData()?.isSuperAdmin,
  };
  const searchData = { ...comparisonPayload, ...userDetails };
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [isFromDateOpened, setIsFromDateOpened] = useState(false);
  const [isToDateOpened, setIsToDateOpened] = useState(false);
  const [isRUSUOpened, setIsRUSUOpened] = useState(false);
  const [isMyComparison, setIsMyComparison] = useState<boolean>(false);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [RUSUdata, setRUSUdata] = useState<RUSU[]>([]);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [openProjectNotFoundModal, setOpenProjectNotFoundModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Comparison[]>([]);
  const [searchPayload, setSearchPayload] = useState(searchData);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [selectedRUSUs, setSelectedRUSUs] = useState<[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const { post, fileDownload, get } = useCallApi();
  const navigate = useNavigate();
  const [competitorList, setCompetitorsList] = useState<COMP[]>([]);
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<any[]>([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState<any[]>([]);
  const [itemQuality, setItemQuality] = useState<string>();
  const [itemFunctionality, setItemFunctionality] = useState<string>();
  const [photo, setPhoto] = useState<string>();
  const [competition, setCompetition] = useState<string>('2');
  const [rocList, setRocList] = useState([]);
  const [priceClass, setPriceClass] = useState([]);
  const [strategicPricing, setStrategicPricing] = useState([]);
  const [sellingPriceList, setSellingPriceList] = useState([]);
  const [rspList, setRspLists] = useState([]);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    sortEntity: string, typeOfSort: SortOrder | undefined
  }>({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
  const didMountRef = useRef(false);
  const [totalComparisonCount, setTotalComparisonCount] = useState<number>(0);
  const [paginationItemCount, setPaginationItemCount] = useState<number>(0);
  const [allFromDate, setAllFromDate] = useState<Dayjs | null>(null);
  const [allToDate, setAllToDate] = useState<Dayjs | null>(null);
  const [checkedAllHFBs, setcheckedAllHFBs] = useState<[]>([]);
  const [checkedAllRUSUs, setcheckedAllRUSUs] = useState<[]>([]);
  const [selectedAllHFBs, setSelectedAllHFBs] = useState<[]>([]);
  const [selectedAllPRAs, setSelectedAllPRAs] = useState<[]>([]);
  const [selectedAllPAs, setSelectedAllPAs] = useState<[]>([]);
  const [selectedAllRUs, setSelectedAllRUs] = useState<[]>([]);
  const [selectedAllSUs, setSelectedAllSUs] = useState<[]>([]);
  const [selectedAllROCNos, setSelectedAllROCNos] = useState<[]>([]);
  const [selectedAllPCNos, setSelectedAllPCNos] = useState<[]>([]);
  const [selectedAllSPNos, setSelectedAllSPNos] = useState<[]>([]);
  const [selectedAllSPRNos, setSelectedAllSPRNos] = useState<[]>([]);
  const [selectedAllRSPNos, setSelectedAllRSPNos] = useState<[]>([]);
  const [allMyComparison, setAllMyComparison] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [itemNo, setItemNo] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [itemName, setItemName] = useState<any>();
  const isICDetails = location.state?.isICDetails || false;
  const isComparisonCountClick = isNullEmptyOrUndefined(window.location?.search);
  const [allSuCodes, setAllSuCodes] = useState<[]>([]);
  const dispatch = useAppDispatch();
  const searchApiData: any = searchPayload;
  const urlParam = new URLSearchParams(window.location.search);
  const itemsTabItemNo: any = urlParam?.get('item');
  const comparisonProjectId: any = urlParam?.get('id');
  const [projectId, setProjectId] = useState<any>('');

  const setRocData = (data: []) => {
    const updatedRocData: any = [];
    data.map((item: ROC, index) => (updatedRocData.push(
      { key: index, value: item.rocNo, label: item.rocName },
    )));
    setRocList(updatedRocData);
  };

  const setPriceLevelData = (data: any) => {
    const updatedPlData: any = [];
    const updatedSlData: any = [];
    data?.priceClass.map((item: PC, index: number) => (updatedPlData.push(
      { key: index, value: item.pcNo, label: item.pcName },
    )));
    setPriceClass(updatedPlData);
    data?.strategicPricing.map((item: SP, index: number) => (updatedSlData.push(
      { key: index, value: item.spNo, label: item.spName },
    )));
    setStrategicPricing(updatedSlData);
  };

  const setSellingPriceAndRSPData = (data: any) => {
    const sPData = data.filter((item: any) => item.groupName === 'Selling Price');
    const rspData = data.filter((item: any) => item.groupName === 'Recommended sales price');
    const allSuData = data.filter((item: any) => item.groupName === 'SUCode');
    setAllSuCodes(allSuData);
    const updatedSPData: any = [];
    const updatedRspData: any = [];
    sPData.map((item: SPR, index: number) => (updatedSPData.push(
      { sprKey: index, value: item.key, label: item.value },
    )));
    setSellingPriceList(updatedSPData);
    rspData.map((item: RSP, index: number) => (updatedRspData.push(
      { sprKey: index, value: item.key, label: item.value },
    )));
    setRspLists(updatedRspData);
  };

  const getHFB = () => {
    get('business-structure', setHFBdata);
  };

  const getROC = () => {
    get('rocs', setRocData);
  };

  const getPriceLevel = () => {
    get('price-level', setPriceLevelData);
  };

  const getSellingPriceAndRSP = () => {
    get('enum-lookups', setSellingPriceAndRSPData);
  };

  const fetchFilterValues = () => {
    dispatch(setLoader(true));
    const payload = {
      ProjectType: projectType(),
      UnitCode: isIos() ? null : userRu(),
    };
    post('Comparison-SearchFilter', payload, (data: any) => {
      setRUSUdata(data.RU);
      setCompetitorsList(data.CP);
      (!isICDetails && isComparisonCountClick) && dispatch(setLoader(false));
    }, () => {
      (!isICDetails && isComparisonCountClick) && dispatch(setLoader(false));
    });
  };

  useEffect(() => {
    getHFB();
    getROC();
    getPriceLevel();
    getSellingPriceAndRSP();
    fetchFilterValues();
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      setSelectedRU(data);
    });
  }, []);

  const editItemCriteria = (field: any | undefined) => {
    switch (field) {
      case '10':
        return 'better';
      case '20':
        return 'equal';
      case '30':
        return 'worse';
      default:
        return undefined;
    }
  };

  const editPhotoYorN = (field: string | null) => {
    if (field === '1') {
      return 'photo';
    }
    if (field === '0') {
      return 'noPhoto';
    }
    return undefined;
  };

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  const setHFBKeys = (filters: any) => {
    if (!isNullEmptyOrUndefined(filters?.HfbNos)) {
      return filters?.HfbNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PraNos)) {
      return filters?.PraNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PaNos)) {
      return filters?.PaNos;
    }
    return [];
  };

  const setRUSUKeys = (filters: any) => {
    if (!isNullEmptyOrUndefined(filters?.RuCodes)) {
      return filters?.RuCodes;
    }
    if (!isNullEmptyOrUndefined(filters?.SuCodes)) {
      return filters?.SuCodes;
    }
    return [];
  };

  const setSearchInput = (val: any) => {
    const searchInput:any = document?.getElementById('fcSearch');
    if (searchInput) {
      searchInput.value = val;
    }
    const event = new Event('input', { bubbles: true });
    searchInput?.dispatchEvent(event);
  };

  useEffect(() => {
    if (location.state?.isICDetails === true) {
      if (localStorage.getItem('selectedFCFilters')) {
        const filters = JSON.parse(localStorage.getItem('selectedFCFilters') as any);
        const comparsionChecked = !isNullEmptyOrUndefined(filters?.UserId);
        // Page Filters
        setSelectedHFBs(setHFBKeys(filters));
        setSelectedRUSUs(setRUSUKeys(filters));
        const isFromDate = isNullEmptyOrUndefined(filters?.FromDate);
        const isToDate = isNullEmptyOrUndefined(filters?.ToDate);
        setStartDate(isFromDate === false ? dayjs(filters?.FromDate) : null);
        setEndDate(isToDate === false ? dayjs(filters?.ToDate) : null);
        setIsMyComparison(comparsionChecked);
        // All Filters
        setcheckedAllHFBs(setHFBKeys(filters));
        setSelectedAllHFBs(filters?.HfbNos);
        setSelectedAllPRAs(filters?.PraNos);
        setSelectedAllPAs(filters?.PaNos);
        setAllFromDate(isFromDate === false ? dayjs(filters?.FromDate) : null);
        setAllToDate(isToDate === false ? dayjs(filters?.ToDate) : null);
        setcheckedAllRUSUs(setRUSUKeys(filters));
        setSelectedAllRUs(filters?.RuCodes);
        setSelectedAllSUs(filters?.SuCodes);
        setAllMyComparison(comparsionChecked);
        setSelectedCompetitorId(filters?.CompetitorIds);
        setItemQuality(editItemCriteria(filters?.ItemQuality));
        setItemFunctionality(editItemCriteria(filters?.ItemFunctionality));
        setPhoto(editPhotoYorN(filters?.Photos));
        setCompetition(filters?.NoCompetition.toString());
        setSelectedAllROCNos(filters?.RocNos);
        setSelectedAllPCNos(filters?.PcNos);
        setSelectedAllSPNos(filters?.SpNos);
        setSelectedAllSPRNos(filters?.SellingPrice);
        setSelectedAllRSPNos(filters?.RecSalesPrice);
        setProjectId(filters?.ProjectId);
        setItemNo(filters?.ItemNo);
        setItemName(filters?.ItemName);
        setSearchText(filters?.ItemNo || filters?.ItemName);
        !isNullEmptyOrUndefined(filters?.ItemNo) && setSearchInput(filters?.ItemNo);
        !isNullEmptyOrUndefined(filters?.ItemName) && setSearchInput(filters?.ItemName);
        // eslint-disable-next-line no-unsafe-optional-chaining
        const fcSortEntity = filters?.SortEntity?.charAt(0).toLowerCase() + filters?.SortEntity?.slice(1);
        setSortConfig({
          sortEntity: fcSortEntity,
          typeOfSort: filters?.TypeOfSort === 'desc' ? 'descend' : 'ascend',
        });
        setSearchInput(filters?.ItemNo);
        setSearchInput(filters?.ItemName);
        setSearchPayload(filters);
      }
    }
  }, []);

  useEffect(() => {
    if (isICDetails === false) {
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
    }
  }, [isICDetails]);

  // Comparison count click from Items tab and Project details screen
  useEffect(() => {
    if (!isComparisonCountClick) {
      if (!isNullEmptyOrUndefined(itemsTabItemNo) && !isNullEmptyOrUndefined(comparisonProjectId)) {
        const ProjectDetailsPayload = {
          ...searchData,
          ItemNo: itemsTabItemNo,
          ProjectId: comparisonProjectId,
        };
        setItemNo(itemsTabItemNo);
        setProjectId(comparisonProjectId);
        setPaginationItemCount(0);
        setSearchResults([]);
        setSearchPayload(ProjectDetailsPayload);
        setSearchInput(itemsTabItemNo);
        localStorage.removeItem('selectedFCFilters');
        localStorage.setItem('selectedFCFilters', JSON.stringify(ProjectDetailsPayload));
      } else {
        const itemsTabDate = dayjs().subtract(1, 'years').format('YYYY-MM-DD');
        const itemsTabPayload = {
          ...searchData,
          ItemNo: itemsTabItemNo,
          FromDate: itemsTabDate,
        };
        setItemNo(itemsTabItemNo);
        setStartDate(dayjs(itemsTabDate));
        setAllFromDate(dayjs(itemsTabDate));
        setPaginationItemCount(0);
        setSearchResults([]);
        setSearchPayload(itemsTabPayload);
        setSearchInput(itemsTabItemNo);
        localStorage.removeItem('selectedFCFilters');
        localStorage.setItem('selectedFCFilters', JSON.stringify(itemsTabPayload));
      }
    }
  }, []);

  const getComparisonCount = () => {
    if (JSON.stringify(searchPayload) !== JSON.stringify(searchData)) {
      post(
        'comparison-count',
        searchPayload,
        (response: any) => {
          setTotalComparisonCount(response.comparisonCount);
          toggleLoader(false);
        },
        (error: any) => {
          toggleLoader(false);
        },
      );
    } else {
      toggleLoader(false);
      setTotalComparisonCount(0);
    }
  };

  const setFilterShow = () => {
    const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(searchData);
    return checkFilterExist;
  };

  useEffect(() => {
    if (didMountRef.current) {
      const checkFilterExist = JSON.stringify(searchPayload) !== JSON.stringify(searchData);
      setHasFilter(setFilterShow());
      if (checkFilterExist) {
        toggleLoader(true);
        post(
          'search-comparison',
          searchPayload,
          (response: Comparison[]) => {
            setSearchResults([...searchResults, ...response]);
            dispatch(setCompResults(response));
            if (response.length > 0) {
              setIsDownload(true);
            }
            // restricted the count call
            if (!triggerShowmore) {
              getComparisonCount();
            } else {
              toggleLoader(false);
            }
          },
          (error: any) => {
            setSearchResults([]);
            dispatch(setCompResults([]));
            toggleLoader(false);
          },
        );
      } else {
        setSearchResults([]);
        dispatch(setCompResults([]));
        toggleLoader(false);
      }
      setTriggerShowmore(false);
    }
    didMountRef.current = true;
  }, [searchPayload]);

  const downloadExcel = () => {
    dispatch(setLoader(true));
    fileDownload('export-comparisons', searchPayload, (response: any) => {
      jsdownload(response, 'Comparison-Details.xlsx');
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const handleTableChange: TableProps<Comparison>['onChange'] = (pagination, filters, sorter, extra) => {
    const sortEntity = ((sorter as SorterResult<Comparison>).field) as string;
    const sortTypeParam = (sorter as SorterResult<Comparison>).order;
    const typeOfSort = (!sortTypeParam || sortTypeParam === 'ascend') ? 'asc' : 'desc';
    setSortConfig({ sortEntity, typeOfSort: (sorter as SorterResult<Comparison>).order || 'ascend' });
    setPaginationItemCount(0);
    setSearchResults([]);
    const fcSortPayload = {
      ...searchPayload,
      SortEntity: capitalizeFirstLetter(sortEntity),
      TypeOfSort: typeOfSort,
      StartWith: 0,
    };
    setSearchPayload(fcSortPayload);
    localStorage.removeItem('selectedFCFilters');
    localStorage.setItem('selectedFCFilters', JSON.stringify(fcSortPayload));
  };

  const onSearchChange = (e: any, data: any) => {
    const searchTerm = data?.value || null;
    let searchCriteria = { ItemName: '', ItemNo: '', StartWith: 0 };
    if (searchTerm === null) {
      setSearchResults([]);
      setItemNo(null);
      setItemName(null);
      setSearchText(null);
      localStorage.removeItem('selectedFCFilters');
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchPayload));
    } else {
      setSearchText(searchTerm);
      const enteredInput = searchTerm.trim()
        .replace(/\./g, '') // Replace all dots
        .replace(/-/g, ''); // Replace all hyphens

      if (/^CST\d/.test(enteredInput)) {
        setItemNo(enteredInput);
        setItemName('');
        searchCriteria = { ItemName: '', ItemNo: enteredInput, StartWith: 0 };
      } else if (!isNaN(enteredInput) && enteredInput.length === 8) {
        setItemNo(searchTerm);
        setItemName('');
        searchCriteria = { ItemName: '', ItemNo: searchTerm.trim(), StartWith: 0 };
      } else {
        setItemName(searchTerm);
        setItemNo('');
        searchCriteria = { ItemName: searchTerm.trim(), ItemNo: '', StartWith: 0 };
      }
      setPaginationItemCount(0);
      setSearchResults([]);
      localStorage.removeItem('selectedFCFilters');
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchPayload));
    }
  };

  const itemCriteria = (field: string | undefined) => {
    switch (field) {
      case 'better':
        return '10';
      case 'equal':
        return '20';
      case 'worse':
        return '30';
      default:
        return null;
    }
  };

  const onCheckPhotoYorN = (field: string | undefined) => {
    switch (field) {
      case 'photo':
        return '1';
      case 'noPhoto':
        return '0';
      default:
        return null;
    }
  };

  const onCheckOnlineYorN = (field: string | undefined) => {
    switch (field) {
      case 'online':
        return '1';
      case 'inStore':
        return '0';
      default:
        return null;
    }
  };

  // Clear all Filters
  const clearAllFilter = () => {
    // page filter
    setSelectedHFBs([]);
    setSelectedRUSUs([]);
    setStartDate(null);
    setEndDate(null);
    setIsMyComparison(false);
    setItemNo(null);
    setItemName(null);
    // sidebar fileters
    setAllFromDate(null);
    setAllToDate(null);
    setSelectedAllHFBs([]);
    setSelectedAllPRAs([]);
    setSelectedAllPAs([]);
    setAllMyComparison(false);
    setItemQuality(undefined);
    setItemFunctionality(undefined);
    setPhoto(undefined);
    setCompetition('2');
    setSelectedCompetitorId([]);
    setSelectedCompetitor([]);
    setSelectedAllRUs([]);
    setSelectedAllSUs([]);
    setSelectedAllROCNos([]);
    setSelectedAllSPNos([]);
    setSelectedAllPCNos([]);
    setSelectedAllSPRNos([]);
    setSelectedAllRSPNos([]);
    setPaginationItemCount(0);
    setTotalComparisonCount(0);
    setcheckedAllHFBs([]);
    setcheckedAllRUSUs([]);
    setSearchResults([]);
    dispatch(setCompResults([]));
    setIsFilterSelected(false);
    setIsDownload(false);
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
    setSearchInput('');
    setSearchText('');
    setProjectId('');
    setSearchPayload(searchData);
  };

  const checkAllFilterPrerequistie = () => {
    if (
      !isNullEmptyOrUndefined(selectedHFBs)
      || !isNullEmptyOrUndefined(selectedRUSUs)
      || !isNullEmptyOrUndefined(startDate)
      || !isNullEmptyOrUndefined(endDate)
      || !isNullEmptyOrUndefined(itemNo)
      || !isNullEmptyOrUndefined(itemName)
      || !(isMyComparison === false)
      || !isNullEmptyOrUndefined(allFromDate)
      || !isNullEmptyOrUndefined(allToDate)
      || !isNullEmptyOrUndefined(selectedAllHFBs)
      || !isNullEmptyOrUndefined(selectedAllPRAs)
      || !isNullEmptyOrUndefined(selectedAllPAs)
      || !(allMyComparison === false)
      || !isNullEmptyOrUndefined(itemQuality)
      || !isNullEmptyOrUndefined(itemFunctionality)
      || !isNullEmptyOrUndefined(itemQuality)
      || !isNullEmptyOrUndefined(photo)
      || !isNullEmptyOrUndefined(competition)
      || !isNullEmptyOrUndefined(selectedCompetitor)
      || !isNullEmptyOrUndefined(selectedAllRUs)
      || !isNullEmptyOrUndefined(selectedAllSUs)
      || !isNullEmptyOrUndefined(selectedAllROCNos)
      || !isNullEmptyOrUndefined(selectedAllSPNos)
      || !isNullEmptyOrUndefined(selectedAllPCNos)
      || !isNullEmptyOrUndefined(selectedAllSPRNos)
      || !isNullEmptyOrUndefined(selectedAllRSPNos)) {
      return false;
    }
    return true;
  };

  const disableClearAndViewButton = () => {
    if (!isNullEmptyOrUndefined(searchResults)) {
      return false;
    }
    return checkAllFilterPrerequistie();
  };

  const getunitCode = () => {
    if (isRu()) {
      return selectedRU?.ruCode;
    }
    if (isSu()) {
      return RUSUdata[0].id;
    }
    return null;
  };

  // Adding this logic antd Tree component sending all child values to chips
  const removeCommon = (first: any, second: any) => {
    const combine = [...first, ...second];
    return combine.filter((el) => !(first.includes(el) && second.includes(el)));
  };

  const setAllFilterHFBValues = () => {
    const isHfbChecked = checkedAllHFBs.some((hfb) => selectedAllHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedAllHFBs) && isHfbChecked) {
      return selectedAllHFBs;
    }
    return null;
  };

  const setAllFilterPRAValues = () => {
    const isHfbChecked = checkedAllHFBs.some((hfb) => selectedAllHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedAllHFBs) && isHfbChecked) {
      const praFilterData:any = [];
      selectedAllHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            if (hfb === hfbList.id) {
              praFilterData.push(pra.id);
            }
          });
        });
      });
      const isPraChecked = selectedAllPRAs.some((pra) => praFilterData.includes(pra));
      if (selectedAllPRAs.length === 0) {
        return selectedAllPRAs;
      }
      if (!isPraChecked) {
        return selectedAllPRAs;
      }
      const selectedPraNos:any = removeCommon(selectedAllPRAs, praFilterData);
      setSelectedAllPRAs(selectedPraNos);
      return selectedPraNos;
    }
    return selectedAllPRAs;
  };

  const setAllFilterPAValues = () => {
    const paFilterData:any = [];
    if (!isNullEmptyOrUndefined(selectedAllPRAs)) {
      selectedAllPRAs.forEach((pra: any) => {
        selectedAllPAs.forEach((pa: any) => {
          if (pa.slice(0, 3) === pra) {
            paFilterData.push(pa);
          }
        });
      });
      if (selectedAllPAs.length === 0) {
        return selectedAllPAs;
      }
      const selectedPaNos:any = removeCommon(selectedAllPAs, paFilterData);
      setSelectedAllPAs(selectedPaNos);
      return selectedPaNos;
    }
    if (!isNullEmptyOrUndefined(selectedAllHFBs)) {
      selectedAllHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            pra.items.forEach((pa: any) => {
              if (hfb === hfbList.id) {
                paFilterData.push(pa.id);
              }
            });
          });
        });
      });
      if (selectedAllPAs.length === 0) {
        return selectedAllPAs;
      }
      const selectedPaNos:any = removeCommon(selectedAllPAs, paFilterData);
      setSelectedAllPAs(selectedPaNos);
      return selectedPaNos;
    }
    return selectedAllPAs;
  };

  const setAllFilterRUValues = () => {
    const isRUChecked = checkedAllRUSUs.some((ru) => selectedAllRUs.includes(ru));
    if (!isNullEmptyOrUndefined(selectedAllRUs) && isRUChecked) {
      return selectedAllRUs;
    }
    return null;
  };

  const setAllFilterSUValues = () => {
    const isRuChecked = checkedAllRUSUs.some((ru) => selectedAllRUs.includes(ru));
    if (!isNullEmptyOrUndefined(selectedAllRUs) && isRuChecked) {
      const suFilterData:any = [];
      selectedAllRUs.forEach((ru: any) => {
        allSuCodes.forEach((suList: any) => {
          if (ru === suList.value) {
            suFilterData.push(suList.key);
          }
        });
      });
      const isSuChecked = selectedAllSUs.some((su) => suFilterData.includes(su));
      if (selectedAllSUs.length === 0) {
        return selectedAllSUs;
      }
      if (!isSuChecked) {
        return selectedAllSUs;
      }
      const selectedSuNos:any = removeCommon(selectedAllSUs, suFilterData);
      setSelectedAllSUs(selectedSuNos);
      return selectedSuNos;
    }
    return selectedAllSUs;
  };

  // Building a payload for search comparison
  const buildAllFilterPayload = (pageFilter: boolean) => {
    const payload = {
      ItemName: itemName || '',
      ItemNo: itemNo || '',
      FromDate: allFromDate ? allFromDate.format('YYYY-MM-DD') : null,
      ToDate: allToDate ? allToDate.format('YYYY-MM-DD') : null,
      HfbNos: setAllFilterHFBValues() || [],
      PraNos: setAllFilterPRAValues() || [],
      PaNos: setAllFilterPAValues() || [],
      UserId: allMyComparison === true ? userData()?.userId : null,
      UnitCode: getunitCode(),
      ProjectType: projectType(),
      ItemQuality: itemCriteria(itemQuality) || null,
      ItemFunctionality: itemCriteria(itemFunctionality) || null,
      Photos: onCheckPhotoYorN(photo) || null,
      CompetitorIds: selectedCompetitorId || [],
      NoCompetition: !isNullEmptyOrUndefined(competition) ? Number(competition) : 2,
      CompetitorUnitCodes: [],
      RuCodes: setAllFilterRUValues() || [],
      SuCodes: setAllFilterSUValues() || [],
      RocNos: selectedAllROCNos || [],
      SpNos: selectedAllSPNos || [],
      PcNos: selectedAllPCNos || [],
      StartWith: 0,
      TotalCount: 25,
      CurrentUserId: userData()?.userId || null,
      IsMobile: true,
      SortEntity: 'ComparedOn',
      TypeOfSort: 'desc',
      IsSuperAdmin: userData()?.isSuperAdmin,
      SellingPrice: selectedAllSPRNos || [],
      RecSalesPrice: selectedAllRSPNos || [],
      ProjectId: projectId,
    };
    setPaginationItemCount(0);
    setIsFilterSelected(false);
    const fcPayload = pageFilter === true ? searchApiData : payload;
    setHasFilter(setFilterShow());
    setSearchPayload(fcPayload);
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });
    localStorage.removeItem('selectedFCFilters');
    localStorage.setItem('selectedFCFilters', JSON.stringify(fcPayload));
  };

  const triggerAllFilterPayload = () => {
    setSearchResults([]);
    if (checkAllFilterPrerequistie()) {
      clearAllFilter();
    } else {
      buildAllFilterPayload(false);
    }
  };

  const sideFiltersPrerequiste = () => {
    if (
      !isNullEmptyOrUndefined(selectedCompetitorId)
      || !isNullEmptyOrUndefined(itemQuality)
      || !isNullEmptyOrUndefined(itemFunctionality)
      || !isNullEmptyOrUndefined(photo)
      || !isNullEmptyOrUndefined(competition)
      || !isNullEmptyOrUndefined(selectedAllROCNos)
      || !isNullEmptyOrUndefined(selectedAllSPNos)
      || !isNullEmptyOrUndefined(selectedAllPCNos)
      || !isNullEmptyOrUndefined(selectedAllSPRNos)
      || !isNullEmptyOrUndefined(selectedAllRSPNos)) {
      return true;
    }
    return false;
  };

  // Page Filter Selection payload
  const onCheckboxChange = (selection: any, type: string) => {
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    let RUData: any = [];
    let SUData: any = [];
    let isStartDate: any;
    let isEndDate: any;
    let isComparison: any;
    searchApiData.UserId = null;

    switch (type) {
      case 'hfb':
        setSelectedHFBs(selection);
        setcheckedAllHFBs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                HFBData.push(val);
                setSelectedAllHFBs(HFBData);
                break;
              case 3:
                PRAData.push(val);
                setSelectedAllPRAs(PRAData);
                break;
              case 4:
                PAData.push(val);
                setSelectedAllPAs(PAData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          HFBData = []; PRAData = []; PAData = [];
          setSelectedHFBs([]);
          setcheckedAllHFBs([]);
          setSelectedAllHFBs([]);
          setSelectedAllPRAs([]);
          setSelectedAllPAs([]);
        }
        break;
      case 'rusu':
        setSelectedRUSUs(selection);
        setcheckedAllRUSUs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                RUData.push(val);
                setSelectedAllRUs(RUData);
                break;
              case 3:
                SUData.push(val);
                setSelectedAllSUs(SUData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          RUData = []; SUData = [];
          setcheckedAllRUSUs([]);
          setSelectedRUSUs([]);
          setSelectedAllRUs([]);
          setSelectedAllSUs([]);
        }
        break;
      case 'startDate':
        isStartDate = selection;
        setAllFromDate(selection);
        break;
      case 'endDate':
        isEndDate = selection;
        setAllToDate(selection);
        break;
      case 'myComparsion':
        isComparison = selection;
        setAllMyComparison(isComparison);
        break;
      default:
        break;
    }
    searchApiData.UnitCode = getunitCode();
    searchApiData.ProjectType = projectType();
    searchApiData.CurrentUserId = userData()?.userId;
    searchApiData.IsSuperAdmin = userData()?.isSuperAdmin;
    searchApiData.StartWith = 0;
    searchApiData.TotalCount = 25;
    searchApiData.TypeOfSort = 'desc';
    searchApiData.SortEntity = 'ComparedOn';
    searchApiData.ProjectId = projectId;
    setSortConfig({ sortEntity: 'comparedOn', typeOfSort: 'descend' });

    searchApiData.CompetitorIds = !isNullEmptyOrUndefined(selectedCompetitorId) ? selectedCompetitorId : [];
    searchApiData.ItemQuality = !isNullEmptyOrUndefined(itemQuality) ? itemCriteria(itemQuality) : null;
    searchApiData.ItemFunctionality = !isNullEmptyOrUndefined(itemFunctionality)
      ? itemCriteria(itemFunctionality) : null;
    searchApiData.Photos = !isNullEmptyOrUndefined(photo) ? onCheckPhotoYorN(photo) : null;
    searchApiData.NoCompetition = !isNullEmptyOrUndefined(competition) ? Number(competition) : 2;
    searchApiData.RocNos = !isNullEmptyOrUndefined(selectedAllROCNos) ? selectedAllROCNos : [];
    searchApiData.SpNos = !isNullEmptyOrUndefined(selectedAllSPNos) ? selectedAllSPNos : [];
    searchApiData.PcNos = !isNullEmptyOrUndefined(selectedAllPCNos) ? selectedAllPCNos : [];
    searchApiData.SellingPrice = !isNullEmptyOrUndefined(selectedAllSPRNos) ? selectedAllSPRNos : [];
    searchApiData.RecSalesPrice = !isNullEmptyOrUndefined(selectedAllRSPNos) ? selectedAllRSPNos : [];

    // checking filters selection
    const sideFilterSelected = sideFiltersPrerequiste();
    const pageFilterSelected = (!isNullEmptyOrUndefined(searchApiData.HfbNos)
    || !isNullEmptyOrUndefined(searchApiData.PraNos) || !isNullEmptyOrUndefined(searchApiData.PaNos)
    || !isNullEmptyOrUndefined(searchApiData.RuCodes) || !isNullEmptyOrUndefined(searchApiData.SuCodes)
    || !isNullEmptyOrUndefined(searchApiData.FromDate) || !isNullEmptyOrUndefined(searchApiData.ToDate)
    || !isNullEmptyOrUndefined(allFromDate) || !isNullEmptyOrUndefined(allToDate)
    || !isNullEmptyOrUndefined(searchApiData.ItemNo) || !isNullEmptyOrUndefined(searchApiData.ItemName)
    || !isNullEmptyOrUndefined(searchApiData.UserId));
    const unCheckHfb = ((!pageFilterSelected && !isNullEmptyOrUndefined(checkedAllRUSUs)) || allMyComparison);
    const unCheckRuSu = ((!pageFilterSelected && !isNullEmptyOrUndefined(checkedAllHFBs)) || allMyComparison);
    const unCheckComparsion = !pageFilterSelected && (!isNullEmptyOrUndefined(checkedAllHFBs)
    || !isNullEmptyOrUndefined(checkedAllRUSUs));

    if ((unCheckHfb || unCheckRuSu)) {
      if ((!unCheckComparsion && !pageFilterSelected) || (unCheckComparsion && !allMyComparison)) {
        searchApiData.UserId = null;
      } else if (!pageFilterSelected || unCheckComparsion || allMyComparison) {
        searchApiData.UserId = userData()?.userId;
      }
    }

    // check prerequiste
    if ((!sideFilterSelected && !pageFilterSelected
      && (!unCheckHfb || !unCheckRuSu || !unCheckComparsion))) {
      disableClearAndViewButton();
      clearAllFilter();
      localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
    } else {
      buildAllFilterPayload(true);
    }
  };

  // All Filter Selection
  const allFilterCheckboxChange = (selection: [], type: string) => {
    const allHFBData: any = [];
    const allPRAData: any = [];
    const allPAData: any = [];
    const allRUData: any = [];
    const allSUData: any = [];
    switch (type) {
      case 'allHFB':
        setcheckedAllHFBs(selection);
        setSelectedHFBs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                allHFBData.push(val);
                setSelectedAllHFBs(allHFBData);
                break;
              case 3:
                allPRAData.push(val);
                setSelectedAllPRAs(allPRAData);
                break;
              case 4:
                allPAData.push(val);
                setSelectedAllPAs(allPAData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          setSelectedHFBs([]);
          setcheckedAllHFBs([]);
          setSelectedAllHFBs([]);
          setSelectedAllPRAs([]);
          setSelectedAllPAs([]);
        }
        break;
      case 'allRUSU':
        setcheckedAllRUSUs(selection);
        setSelectedRUSUs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                allRUData.push(val);
                setSelectedAllRUs(allRUData);
                break;
              case 3:
                allSUData.push(val);
                setSelectedAllSUs(allSUData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          setcheckedAllRUSUs([]);
          setSelectedRUSUs([]);
          setSelectedAllRUs([]);
          setSelectedAllSUs([]);
        }
        break;
      case 'roc':
        setSelectedAllROCNos(selection);
        break;
      case 'pc':
        setSelectedAllPCNos(selection);
        break;
      case 'sp':
        setSelectedAllSPNos(selection);
        break;
      case 'spr':
        setSelectedAllSPRNos(selection);
        break;
      case 'rsp':
        setSelectedAllRSPNos(selection);
        break;
      case 'allMyComparison':
        if (allMyComparison === false) {
          setAllMyComparison(true);
          setIsMyComparison(true);
        } else {
          setAllMyComparison(false);
          setIsMyComparison(false);
        }
        break;
      default:
        break;
    }
  };

  const dropdownClick = (isOpen: boolean, type: string) => {
    switch (type) {
      case 'hfb':
        setIsHFBOpened(isOpen);
        break;
      case 'rusu':
        setIsRUSUOpened(isOpen);
        break;
      default:
        break;
    }
  };

  const onStartDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    setEndDate(null);
    if (date) {
      setStartDate(date);
      onCheckboxChange(date, 'startDate');
    } else {
      setStartDate(null);
    }
    setIsFromDateOpened(false);
  };

  const onEndDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setEndDate(date);
      onCheckboxChange(date, 'endDate');
    } else {
      setEndDate(null);
    }
    setIsToDateOpened(false);
  };

  const setRadioVal = (e: any, type:string) => {
    switch (type) {
      case 'quality':
        setItemQuality(e.target.value);
        break;
      case 'functionality':
        setItemFunctionality(e.target.value);
        break;
      case 'photoYorN':
        setPhoto(e.target.value);
        break;
      case 'competition':
        setCompetition(e.target.value);
        break;
      default:
        break;
    }
  };

  const setCompetitor = (name:any, option:any) => {
    const compId: any = [];
    option.forEach((competitor: any) => compId.push(competitor.id));
    setSelectedCompetitorId(compId);
    setSelectedCompetitor(name);
  };

  const fromDate = () => (
    <DatePicker
      key="fromDate"
      className={styles.fcDatePicker}
      popupClassName="fcDateDropdown"
      onOpenChange={(open) => setIsFromDateOpened(open)}
      onChange={onStartDateChange}
      value={startDate}
      allowClear={false}
      open={isFromDateOpened === true}
      disabledDate={(d) => !d || d.isAfter(endDate)}
    />
  );

  const toDate = () => (
    <DatePicker
      key="toDate"
      className={styles.fcDatePicker}
      open={isToDateOpened === true}
      onOpenChange={setIsToDateOpened}
      onChange={onEndDateChange}
      value={endDate}
      allowClear={false}
      disabledDate={(d) => !d || d.isSameOrBefore(startDate)}
      popupClassName="fcDateDropdown"
    />
  );

  const myComparisonHandler = () => {
    if (isMyComparison === false) {
      setIsMyComparison(true);
      onCheckboxChange(true, 'myComparsion');
    } else {
      setIsMyComparison(false);
      onCheckboxChange(false, 'myComparsion');
    }
  };

  const qualitySection = (title: string) => (
    <div className="fcRadioButtonWrapper">
      <RadioButton
        className="radioGap"
        id={`item${title}Better`}
        name={`item${title}radio`}
        key={`item${title}Better`}
        value="better"
        label="Better"
        defaultChecked={itemQuality === 'better'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Equal`}
        name={`item${title}radio`}
        key={`item${title}Equal`}
        className="radioGap"
        value="equal"
        label="Equal"
        defaultChecked={itemQuality === 'equal'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Worse`}
        name={`item${title}radio`}
        key={`item${title}Worse`}
        value="worse"
        label="Worse"
        defaultChecked={itemQuality === 'worse'}
        onChange={(val) => setRadioVal(val, title)}
      />
    </div>
  );

  const functionalitySection = (title: string) => (
    <div className="fcRadioButtonWrapper">
      <RadioButton
        className="radioGap"
        id={`item${title}Better`}
        name={`item${title}radio`}
        key={`item${title}Better`}
        value="better"
        label="Better"
        defaultChecked={itemFunctionality === 'better'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Equal`}
        name={`item${title}radio`}
        key={`item${title}Equal`}
        className="radioGap"
        value="equal"
        label="Equal"
        defaultChecked={itemFunctionality === 'equal'}
        onChange={(val) => setRadioVal(val, title)}
      />
      <RadioButton
        id={`item${title}Worse`}
        name={`item${title}radio`}
        key={`item${title}Worse`}
        value="worse"
        label="Worse"
        defaultChecked={itemFunctionality === 'worse'}
        onChange={(val) => setRadioVal(val, title)}
      />
    </div>
  );

  const sidebarClose = () => {
    setIsFilterSelected(false);
    setIsSidebarOpened(false);
  };

  const filterHandler = () => {
    setIsFilterSelected(!isFilterSelected);
    setIsSidebarOpened(!isSidebarOpened);
  };

  const onPanelFromDateChange = (date: any) => {
    setAllToDate(null);
    if (date) {
      setAllFromDate(date);
      setStartDate(date);
    } else {
      setAllFromDate(null);
      setStartDate(null);
    }
  };

  const onPanelToDateChange = (date: any) => {
    if (date) {
      setAllToDate(date);
      setEndDate(date);
    } else {
      setAllToDate(null);
      setEndDate(null);
    }
  };

  const getItemDetails = (project: any, record: any, comparison: any) => {
    const payload = {
      ProjectId: null,
      UnitCode: isIos() ? record?.ruCode : selectedRU?.ruCode,
      UserId: userData()?.userId,
      ItemNo: record?.itemNo,
      ItemType: record?.itemType,
      ProjectType: projectType(),
    };
    post('comparisonitem-information', payload, (data: any) => {
      navigate(
        appRoutes.IC,
        {
          state: {
            comparisonId: record.comparisonProjectId,
            comparisonDetail: comparison,
            isInstantCompare: false,
            isFindCompare: true,
            projectItem: data,
            projectDetail: project,
            projectId: record.projectId,
            ruCode: record.ruCode,
            competitorName: record.competitorName,
            isEdit: project.isEdit,
            isDelete: project.isDelete,
            selectedCompetitors: selectedCompetitor,
            showProjDetails: project.isProjectDetailReq,
          },
        },
      );
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const getComarisonDetails = (project: any, record: any) => {
    const payload = {
      comparisonProjectId: record.comparisonProjectId,
    };
    post('view-comparison', payload, (data: any) => {
      if (!isNullEmptyOrUndefined(record.itemNo)) {
        getItemDetails(project, record, data);
      } else {
        navigate(
          appRoutes.IC,
          {
            state: {
              comparisonId: record.comparisonProjectId,
              comparisonDetail: data,
              isInstantCompare: false,
              isFindCompare: true,
              projectItem: data,
              projectDetail: project,
              projectId: record.projectId,
              ruCode: record.ruCode,
              competitorName: record.competitorName,
              isEdit: project.isEdit,
              isDelete: project.isDelete,
              selectedCompetitors: selectedCompetitor,
              showProjDetails: project.isProjectDetailReq,
            },
          },
        );
        dispatch(setLoader(false));
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const getProjectDetails = (record: any) => {
    dispatch(setLoader(true));
    const payload = {
      IsSuperAdmin: userData()?.isSuperAdmin,
      comparisonId: record?.comparisonProjectId,
      CurrentUserProjectType: projectType(),
      CurrentUserId: userData()?.userId,
      ItemNo: record?.itemNo,
    };
    post('comp-project-detail', payload, (data: any) => {
      if (data?.isFound !== false) {
        getComarisonDetails(data, record);
      } else {
        dispatch(setLoader(false));
        setOpenProjectNotFoundModal(true);
      }
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const onComparisonClick = (record: any) => {
    getProjectDetails(record);
  };

  const sideBar = () => (
    <div>
      <Modal
        visible={isSidebarOpened}
        focusLockProps={{
          disabled: false,
        }}
        handleCloseBtn={sidebarClose}
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          footer={(
            <ModalFooter>
              <div className={styles.fcButtonsWrapper}>
                <Button
                  className={styles.fcActionBtn}
                  text={useTranslate('find.comp.clearAll')}
                  small
                  disabled={disableClearAndViewButton()}
                  onClick={clearAllFilter}
                />
                <Button
                  className={styles.fcActionBtn}
                  text={useTranslate('find.comp.view')}
                  type="primary"
                  disabled={disableClearAndViewButton()}
                  onClick={() => triggerAllFilterPayload()}
                  small
                />
              </div>
            </ModalFooter>
          )}
          header={(
            <ModalHeader
              ariaCloseTxt="Close button text"
              title={useTranslate('find.comp.modalTitle')}
              className={styles.fcAllFiltersTitle}
            />
          )}
        >
          <ModalBody>
            <div className={styles.fcAllFiltersContainer}>
              <Accordion className={styles.accordionContainer}>
                <AccordionItem
                  id="fcHFB"
                  title={useTranslate('tab.items.hfbPlaceholder')}
                >
                  {HFBdata.length > 0 && (
                  <div className="allFilterHfb">
                    <Tree
                      checkable
                      treeData={HFBdata as any}
                      checkedKeys={checkedAllHFBs}
                      fieldNames={{
                        title: 'codeName',
                        key: 'id',
                        children: 'items',
                      }}
                      style={{
                        width: '100%',
                      }}
                      virtual={false}
                      switcherIcon={<SSRIcon paths={IcRightArrow} />}
                      onCheck={(val: any) => allFilterCheckboxChange(val, 'allHFB')}
                    />
                  </div>
                  )}
                </AccordionItem>
                <AccordionItem
                  id="fcFromDate"
                  title={useTranslate('find.comp.fromDate')}
                >
                  <div className="site-calendar-demo-card">
                    <Calendar
                      fullscreen={false}
                      value={allFromDate as Dayjs}
                      onChange={(val: any) => onPanelFromDateChange(val)}
                      disabledDate={(d) => !d || d.isAfter(allToDate)}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcToDate"
                  title={useTranslate('find.comp.toDate')}
                >
                  <div className="site-calendar-demo-card">
                    <Calendar
                      fullscreen={false}
                      value={allToDate as Dayjs}
                      onChange={(val: any) => onPanelToDateChange(val)}
                      disabledDate={(d) => !d || d.isSameOrBefore(allFromDate)}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcRUSU"
                  title={useTranslate('find.comp.ruSuPlaceholder')}
                >
                  {RUSUdata.length > 0 && (
                  <div className="allFilterHfb">
                    <Tree
                      checkable
                      treeData={RUSUdata as any}
                      checkedKeys={checkedAllRUSUs}
                      fieldNames={{
                        title: 'text',
                        key: 'id',
                        children: 'children',
                      }}
                      style={{
                        width: '100%',
                      }}
                      virtual={false}
                      switcherIcon={<SSRIcon paths={IcRightArrow} />}
                      onCheck={(val: any) => allFilterCheckboxChange(val, 'allRUSU')}
                    />
                  </div>
                  )}
                </AccordionItem>
                <hr className={`fc-demo-divider__hr-horizontal ${styles.dividerDefault}`} />
                <div className="fcMyCompCheckBoxWrapper">
                  <Checkbox
                    className={styles.fcAllFiltersText}
                    defaultChecked={allMyComparison}
                    onChange={(val: any) => allFilterCheckboxChange(val, 'allMyComparison')}
                  >
                    {useTranslate('find.comp.myComparison')}
                  </Checkbox>
                </div>
                <AccordionItem
                  id="fcCompetitor"
                  title={useTranslate('find.comp.competitor')}
                  open={!isNullEmptyOrUndefined(selectedCompetitor)}
                >
                  <div className="fcCompetitorListWrapper">
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Select
                        mode="multiple"
                        fieldNames={{
                          label: 'text',
                          value: 'text',
                        }}
                        style={{ width: '100%' }}
                        placeholder={useTranslate('find.comp.searchCompetitor')}
                        defaultValue={selectedCompetitor as any}
                        onChange={(val:any, option:any) => setCompetitor(val, option)}
                        options={competitorList}
                        maxTagCount="responsive"
                      />
                    </Space>
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcFunctionality"
                  title={useTranslate('find.comp.functionality')}
                >
                  {functionalitySection('functionality')}
                </AccordionItem>
                <AccordionItem
                  id="fcQuality"
                  title={useTranslate('find.comp.quality')}
                >
                  {qualitySection('quality')}
                </AccordionItem>
                <AccordionItem
                  id="fcROC"
                  title={useTranslate('find.comp.roc')}
                >
                  <div className="fcAllCheckBoxWrapper">
                    <Checkbox.Group
                      options={rocList}
                      defaultValue={selectedAllROCNos}
                      onChange={(val: any) => allFilterCheckboxChange(val, 'roc')}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcPriceLevel"
                  title={useTranslate('find.comp.priceLevel')}
                >
                  <div className="fcAllCheckBoxWrapper">
                    <Menu>
                      <Menu.ItemGroup title={useTranslate('tab.items.price_class')}>
                        <Menu.Item>
                          <Checkbox.Group
                            options={priceClass}
                            defaultValue={selectedAllPCNos}
                            onChange={(val: any) => allFilterCheckboxChange(val, 'pc')}
                          />
                        </Menu.Item>
                      </Menu.ItemGroup>
                      <Menu.ItemGroup title={useTranslate('tab.items.strategic_pricing')}>
                        <Menu.Item>
                          <Checkbox.Group
                            options={strategicPricing}
                            defaultValue={selectedAllSPNos}
                            onChange={(val: any) => allFilterCheckboxChange(val, 'sp')}
                          />
                        </Menu.Item>
                      </Menu.ItemGroup>
                    </Menu>
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcPhoto"
                  title={useTranslate('find.comp.photo')}
                >
                  <div className="fcRadioButtonWrapper">
                    <RadioButton
                      className="radioGap"
                      id="PhotoRadioButtons-photos"
                      value="photo"
                      label="With Photo(s)"
                      defaultChecked={photo === 'photo'}
                      name="PhotoRadioButtons"
                      onChange={(val) => setRadioVal(val, 'photoYorN')}
                    />
                    <RadioButton
                      className="radioGap"
                      id="YesNoInfoRadioButtons-nophoto"
                      value="noPhoto"
                      label="Without Photo(s)"
                      defaultChecked={photo === 'noPhoto'}
                      name="PhotoRadioButtons"
                      onChange={(val) => setRadioVal(val, 'photoYorN')}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcSellingPrice"
                  title={useTranslate('find.comp.sellingPrice')}
                >
                  <div className="fcAllCheckBoxWrapper">
                    <Checkbox.Group
                      options={sellingPriceList}
                      defaultValue={selectedAllSPRNos}
                      onChange={(val: any) => allFilterCheckboxChange(val, 'spr')}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fccompetition"
                  title={useTranslate('find.comp.competition')}
                >
                  <div className="fcRadioButtonWrapper">
                    <RadioButton
                      className="radioGap"
                      id="competitionRadioButtons-onlyComp"
                      value="0"
                      label="Comparisons only"
                      defaultChecked={competition === '0'}
                      name="competitionRadioButtons"
                      onChange={(val) => setRadioVal(val, 'competition')}
                    />
                    <RadioButton
                      className="radioGap"
                      id="competitionRadioButtons-nocomp"
                      value="1"
                      label="No competition"
                      defaultChecked={competition === '1'}
                      name="competitionRadioButtons"
                      onChange={(val) => setRadioVal(val, 'competition')}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem
                  id="fcRSP"
                  title={useTranslate('find.comp.rsp')}
                >
                  <div className="fcAllCheckBoxWrapper">
                    <Checkbox.Group
                      options={rspList}
                      defaultValue={selectedAllRSPNos}
                      onChange={(val: any) => allFilterCheckboxChange(val, 'rsp')}
                    />
                  </div>
                </AccordionItem>
              </Accordion>
              <hr className={`fc-demo-divider__hr-horizontal ${styles.dividerDefault}`} />
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );

  useEffect(() => {
    if (paginationItemCount > 0) {
      setSearchPayload({ ...searchPayload, StartWith: (paginationItemCount * 25) });
      // searchComparison({ ...searchPayload, StartsWith: (paginationItemCount * 25).toString() });
    }
  }, [paginationItemCount]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const getHeaderHeight = () => {
    const height = document.getElementById('fcHeaderWrapperDiv')?.offsetHeight || 0;
    return height + 148;
  };

  useEffect(() => {
    const fcTablePagination = document.querySelector<HTMLElement>('.paginationWrapper');
    // table onload pagination hide
    if (fcTablePagination) {
      fcTablePagination.style.display = 'none';
    }
  }, [totalComparisonCount, searchResults]);

  useEffect(() => {
    if (isIos() || !isNullEmptyOrUndefined(userRu())) {
      getQualityCheckCount(post);
    }
  }, []);

  useEffect(() => {
    const fcTable = document.querySelector<HTMLElement>('.ant-table-body');
    if (fcTable) {
      fcTable.addEventListener('scroll', () => {
        // eslint-disable-next-line max-len
        const scrollPercentage = (fcTable.scrollTop / (fcTable.scrollHeight - fcTable.clientHeight)) * 100;
        if (scrollPercentage <= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
        }
        if (scrollPercentage >= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = '';
        }
      });
    }
  }, []);

  return (
    <div>
      <PageHeader
        isEnable={isDownload}
        title={useTranslate('find.comp.title')}
        clickFn={downloadExcel}
        buttonLabel={useTranslate('find.comp.downloadExcel')}
      />
      <div className="fcContentWrapper">
        <div className="fcHeaderWrapper" id="fcHeaderWrapperDiv">
          <div className="flexbox">
            <Space className="filters-container">
              {HFBdata.length > 0 && (
              <HFBtree
                projectData={HFBdata}
                isHFBOpened={isHFBOpened}
                dropdownClick={dropdownClick}
                onCheckboxChange={onCheckboxChange}
                defaultVal={selectedHFBs}
              />
              )}
              <Popover
                content={fromDate()}
                key="fromDatePop"
                trigger="click"
                placement="bottomLeft"
                overlayClassName="fcDate"
                onOpenChange={(visible) => { setIsFromDateOpened(visible); setIsToDateOpened(false); }}
              >
                <Pill
                  iconPosition="trailing"
                  label={useTranslate('find.comp.fromDate')}
                  small
                  ssrIcon={isFromDateOpened === true ? IcUpArrow : IcDownArrow}
                  className="fcTitle"
                />
              </Popover>
              <Popover
                content={toDate()}
                trigger="click"
                placement="bottomLeft"
                overlayClassName="fcDate"
                onOpenChange={(visible) => { setIsToDateOpened(visible); setIsFromDateOpened(false); }}
              >
                <Pill
                  iconPosition="trailing"
                  label={useTranslate('find.comp.toDate')}
                  small
                  ssrIcon={isToDateOpened === true ? IcUpArrow : IcDownArrow}
                  className="fcTitle"
                />
              </Popover>
              {RUSUdata.length > 0 && (
              <RUSUTree
                RUSUData={RUSUdata}
                isRUSUOpened={isRUSUOpened}
                dropdownClick={dropdownClick}
                onCheckboxChange={onCheckboxChange}
                defaultVal={selectedRUSUs}
              />
              )}
              <Pill
                iconPosition="trailing"
                label={useTranslate('find.comp.myComparison')}
                small
                selected={isMyComparison}
                onClick={myComparisonHandler}
                className="fcTitle"
              />
            </Space>
            <SearchData
              className="search_pill_fc"
              id="fcSearch"
              placeholder={useTranslate('tab.items.search_items')}
              onSearch={onSearchChange}
              value={searchText}
              onChange={(e, data) => onSearchChange(e, data)}
              onClear={(e, data) => {
                onSearchChange(e, null);
                clearAllFilter();
              }}
            />
            <Pill
              iconPosition="trailing"
              label={useTranslate('find.comp.allFilters')}
              small
              ssrIcon={IcFilter}
              selected={isFilterSelected}
              onClick={filterHandler}
              className="fcTitle"
            />
            <Button
              className="fcSearchBtn"
              type="primary"
              text="Search"
              small
              onClick={triggerAllFilterPayload}
            />
          </div>
          <hr className="fc-demo-divider__hr-horizontal" />
          {hasFilter === true ? (
            <PillsToolbar
              data={searchPayload}
              hfbData={selectedHFBs}
              ruSuData={selectedRUSUs}
              suList={allSuCodes}
              rocList={rocList}
              priceClass={priceClass}
              strategicPricing={strategicPricing}
              sellingPriceList={sellingPriceList}
              rspList={rspList}
              competitorList={competitorList}
              setAllFromDate={setAllFromDate}
              setAllToDate={setAllToDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setItemQuality={setItemQuality}
              setItemFunctionality={setItemFunctionality}
              setPhoto={setPhoto}
              setCompetition={setCompetition}
              setMyComparison={(isMyComparison) => {
                setIsMyComparison(isMyComparison);
                setAllMyComparison(isMyComparison);
              }}
              totalComparison={totalComparisonCount}
              onRemoveCompetitor={(newCompList) => {
                setSelectedCompetitorId(newCompList);
                const tempCompList = [...competitorList];
                const tempCompName: any[] = [];
                tempCompList.forEach((comp, index) => {
                  if (newCompList.includes(comp.id)) {
                    tempCompList[index].isChecked = true;
                    tempCompName.push(tempCompList[index].text);
                  } else {
                    tempCompList[index].isChecked = false;
                  }
                });
                setSelectedCompetitor(tempCompName);
                setCompetitorsList(tempCompList);
              }}
              onRemoveFilter={(newPayload, newHfbData, newRuSuData) => {
                const HFBData: any = [];
                const PRAData: any = [];
                const PAData: any = [];
                setSelectedHFBs(newHfbData);
                setcheckedAllHFBs(newHfbData);
                newHfbData?.map((val: string) => {
                  switch (val.length) {
                    case 2:
                      HFBData.push(val);
                      setSelectedAllHFBs(HFBData);
                      break;
                    case 3:
                      PRAData.push(val);
                      setSelectedAllPRAs(PRAData);
                      break;
                    case 4:
                      PAData.push(val);
                      setSelectedAllPAs(PAData);
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                const RUData: any = [];
                const SUData: any = [];
                setSelectedRUSUs(newRuSuData);
                setcheckedAllRUSUs(newRuSuData);
                newRuSuData?.map((val: string) => {
                  switch (val.length) {
                    case 2:
                      RUData.push(val);
                      setSelectedAllRUs(RUData);
                      break;
                    case 3:
                      SUData.push(val);
                      setSelectedAllSUs(SUData);
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                setHasFilter(setFilterShow());
                setPaginationItemCount(0);
                setSearchResults([]);
                setIsDownload(false);
                setSearchPayload({ ...newPayload, StartWith: 0 });
                localStorage.setItem('selectedFCFilters', JSON.stringify({ ...newPayload, StartWith: 0 }));
                if (JSON.stringify(newPayload) === JSON.stringify(searchData)) {
                  localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
                  clearAllFilter();
                }
              }}
              onClearAll={() => {
                localStorage.setItem('selectedFCFilters', JSON.stringify(searchData));
                clearAllFilter();
                setHasFilter(false);
              }}
            />
          ) : null}
        </div>
        {sideBar()}
        <ComparisonList
          headerHeight={getHeaderHeight()}
          searchResults={searchResults}
          isLoading={isLoading}
          showMoreContent={showMoreContent}
          sortConfig={sortConfig}
          totalComparisonCount={totalComparisonCount}
          paginationItemCount={paginationItemCount}
          handleTableChange={handleTableChange}
          comparisonClick={onComparisonClick}
        />
      </div>
      <CloseModal
        modelOpen={openProjectNotFoundModal}
        closeModal={() => {
          setOpenProjectNotFoundModal(false);
        }}
        saveValid
        closeText="Ok"
        saveText=""
        title={useTranslate('find.comp.compNotFound.title')}
        subText={useTranslate('find.comp.compNotFound.text')}
      />
    </div>
  );
};

export default Filters;
