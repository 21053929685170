/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import IcDelete from '@ingka/ssr-icon/paths/trash-can';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './CompetitorSection.module.css';
import {
  isIos, isNullEmptyOrUndefined, isRu, userData,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';

interface CompetitorSectionProps {
  competitorsList: any;
  selectedCompetitor: any;
  navSrc: string;
  compIndexFrmOutbox: number;
  isEdit: boolean;
  isInstant: boolean;
  isNocomp: boolean;
  selectedItemDetails:any;
  disableNoComp: boolean;
  noCompDeletePc:boolean;
  fromSummary:boolean;
  setCompetitor: (value: string | number, name: string | number) => void;
  onNoCompChecked: (isChecked: boolean) => void;
  setDisableNoComp: (isDisable: boolean) => void;
  onDeleteNoComp: () => void;
}

const CompetitorSection = ({
  competitorsList, selectedCompetitor, navSrc, compIndexFrmOutbox, isEdit, isInstant, isNocomp, selectedItemDetails, disableNoComp, noCompDeletePc,
  fromSummary, setCompetitor, onNoCompChecked, onDeleteNoComp, setDisableNoComp,
}: CompetitorSectionProps) => {
  const [competitorList, setCompetitorsList] = useState<any>(competitorsList || []);
  const [checkNoComp, setCheckNoComp] = useState<boolean>(false);
  const [showDeleteInfo, setShowDeleteInfo] = useState<boolean>(false);

  const [comparisonList, setComparisonList] = useState([]);
  const [selectedComparison, setSelectedComparison] = useState<any>();
  const isEditNCFromOutbox = isNocomp === true && isEdit === true && navSrc === 'outbox';
  const isEditProjComp = isNocomp === false && isEdit === true;
  const deleteInfoText = useTranslate('noComp.deleteInfoText');

  useEffect(() => {
    competitorsList?.length !== 0 && setCompetitorsList(competitorsList);
  }, [competitorsList]);

  useEffect(() => {
    if (noCompDeletePc === true) {
      setDisableNoComp(false);
      onNoCompChecked(false);
    }
  }, [noCompDeletePc]);

  useEffect(() => {
    if (isEditNCFromOutbox === true || (disableNoComp === true && isNocomp === true)) {
      setShowDeleteInfo(true);
    } else setShowDeleteInfo(false);
  }, [isEditNCFromOutbox, disableNoComp, isNocomp]);

  const onNoCompetitionChange = (val: any) => {
    setCheckNoComp(val.target.checked);
    onNoCompChecked(val.target.checked);
  };

  const checkSameItemData = (compValId:any) => {
    const comparisonIndexStr = (disableNoComp === true && isNocomp === true)
      ? compIndexFrmOutbox?.toString() : localStorage.getItem('selectedComparison') || '';
    let selComp:any = {};
    if (comparisonIndexStr) {
      const comparisonIndex = parseInt(comparisonIndexStr, 10);
      if (!isNullEmptyOrUndefined(comparisonList)) {
        setSelectedComparison(comparisonList[comparisonIndex]);
        selComp = comparisonList[comparisonIndex];
      }
    }
    if ((!isNullEmptyOrUndefined(comparisonList) && !isNullEmptyOrUndefined(selectedItemDetails)) || (navSrc === 'outbox' && !isNullEmptyOrUndefined(selComp))) {
      const prjId = (isRu() ? selectedItemDetails?.RetailUnitProjectId : selectedItemDetails?.projectId) || selComp?.ProjectId;
      let compIndex = -1;
      const compData:any = comparisonList.filter((val:any, i) => {
        if (val.ProjectId === prjId
          && val.ItemNo === (selectedItemDetails?.itemNo || selComp?.ItemNo)
          && val.UserId === userData().userId
          && val.CompetitorId === compValId) {
          compIndex = i;
          return val;
        }
        return null;
      });
      if (compIndex !== -1) {
        localStorage.setItem('selectedComparison', compIndex?.toString());
      } else {
        setSelectedComparison(comparisonList[compIndexFrmOutbox]);
        localStorage.setItem('selectedComparison', compIndexFrmOutbox?.toString());
      }
      if (compData.length !== 0 && compData[0]?.NoCompetition === 0) {
        setDisableNoComp(true);
        onNoCompChecked(false);
      } else if (compData.length !== 0 && compData[0]?.NoCompetition === 1) {
        setDisableNoComp(true);
        onNoCompChecked(true);
      } else {
        setDisableNoComp(false);
        onNoCompChecked(checkNoComp);
      }
    }
  };

  useEffect(() => {
    offlineData.getItem('comparisonList').then((data: any) => {
      setComparisonList(data);
      const comparisonIndexStr = localStorage.getItem('selectedComparison') || '';
      if (comparisonIndexStr) {
        const comparisonIndex = parseInt(comparisonIndexStr, 10);
        (!isNullEmptyOrUndefined(data)) && setSelectedComparison(data[comparisonIndex]);
      }
    });
  }, []);

  const onCompetitorSelect = (val:any) => {
    checkSameItemData(val.competitorId);
    setCompetitor(val.competitorId, val.competitorName);
  };

  const deleteNoComp = () => {
    onDeleteNoComp();
    localStorage.setItem('deleteLoc', 'competitor');
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.titleWrapper}>
        <SectionTitle sectionTitle={useTranslate('inst.comp.competitorTitle')} />
        {!isIos() && (isEditNCFromOutbox === true || (disableNoComp === true && isNocomp === true)) ? (
          <Button
            className={styles.btDeleteComparison}
            text="Delete"
            ssrIcon={IcDelete}
            type="secondary"
            onClick={deleteNoComp}
            small
          />
        ) : null}
      </div>
      <Select
        showSearch
        className={styles.ruSelect}
        options={competitorList}
        placeholder={useTranslate('inst.comp.competitorPlaceholder')}
        fieldNames={{
          label: 'competitorName',
          value: 'competitorName',
        }}
        defaultOpen={!isEdit}
        disabled={selectedComparison?.NoCompetition === 1 && navSrc === 'outbox' && fromSummary === false}
        value={selectedCompetitor}
        defaultValue={selectedCompetitor}
        defaultActiveFirstOption={false}
        onSelect={(val:any, option:any) => onCompetitorSelect(option)}
        filterOption={
          (inputValue, option) => option?.competitorName
            .toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        dropdownRender={(menu) => menu}
      />
      {(!isIos() && isInstant === false) ? (
        <Checkbox
          id="cbNoComp"
          name="cbNoComp"
          disabled={disableNoComp || isEditNCFromOutbox === true || isEditProjComp}
          className={styles.csCbNoComp}
          checked={isNocomp === true}
          onChange={onNoCompetitionChange}
        >
          No competition
        </Checkbox>
      ) : <div />}

      {(!isIos() && showDeleteInfo === true) && (
      <div className={styles.deleteInfoContainer}>
        <SSRIcon className={styles.deleteNoticeIcon} paths={IcNotice} />
        <Text className={styles.deleteInfoText}>
          {deleteInfoText}
        </Text>
      </div>
      )}
    </div>
  );
};
export default CompetitorSection;
