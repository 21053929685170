/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import Warning from '@ingka/ssr-icon/paths/warning-triangle';
import { isMobile } from 'react-device-detect';
import classes from './UnauthorizedPage.module.css';
import { useTranslate } from '../../services/translationService.js';
import { isIos, isNullEmptyOrUndefined } from '../../services/commonService.js';

const UnauthorizedPage = (props:any) => {
  const { inValid, errorCode } = props;
  const subtext1 = useTranslate('render.unauthorized.subtext1');
  const subtext2 = useTranslate('render.unauthorized.subtext2');
  const iosText = useTranslate('render.unauthorized.iosAccess');
  const inValidText = useTranslate('render.invalid.subtext1');
  const inValidUserText = useTranslate('render.invalidUser.subtext1');
  const inValidSubtext = useTranslate('render.invalid.subtext2');

  return (
    <div className={classes.unauthorizedWrapper}>
      <SSRIcon
        className={isMobile
          ? classes.emptyDataIconMob
          : classes.emptyDataIcon}
        paths={Warning}
      />
      {' '}
      {(inValid === false || isNullEmptyOrUndefined(errorCode)) ? (
        <div>
          <Text
            className={isMobile ? classes.emptyMainTextMob : classes.emptyMainText}
            headingSize="xl"
            tagName="h1"
          >
            <b>{isIos() ? iosText : subtext1}</b>
          </Text>
          {!isIos() && (
          <div className={isMobile ? classes.emptySubTextMob : classes.emptySubText}>
            {subtext2}
          </div>
          )}
        </div>
      )
        : errorCode === 4 ? (
          <div>
            <Text
              className={isMobile ? classes.emptyMainTextMob : classes.emptyMainText}
              headingSize="xl"
              tagName="h1"
            >
              <b>{inValidText}</b>
            </Text>
            <div className={isMobile ? classes.emptySubTextMob : classes.emptySubText}>
              {inValidSubtext}
            </div>
          </div>
        ) : (
          <div>
            <Text
              className={isMobile ? classes.emptyMainTextMob : classes.emptyMainText}
              headingSize="xl"
              tagName="h1"
            >
              <b>{inValidUserText}</b>
            </Text>
            <div className={isMobile ? classes.emptySubTextMob : classes.emptySubText}>
              {inValidSubtext}
            </div>
          </div>
        ) }
    </div>
  );
};

export default UnauthorizedPage;
